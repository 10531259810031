export default class PhysicsCollision {
	constructor({ THREE, Ammo }) {
		let impactPoint = new THREE.Vector3();
		let averageImpactPoint = new THREE.Vector3();
		let averageImpactNormal = new THREE.Vector3();
		let impactNormal = new THREE.Vector3();
		let collisionDispatcher = {}
		this.setCollisionCallback = (a, b, cbfn) => {
			let cd = collisionDispatcher[a];
			(!cd) && (cd = collisionDispatcher[a] = {});
			cd[b] = cbfn
		}
		this.collisionFrame = 2;
		this.collisionIdTop = 0;

		this.dispatchCollision = (ua, ub, touching, contactManifold, threeObject0, threeObject1) => {
			(!ua.collisionData) && (ua.collisionData = {});
			(!ua.collisionId) && (ua.collisionId = this.collisionIdTop++);
			(!ub.collisionId) && (ub.collisionId = this.collisionIdTop++);

			if (touching) {
				let ucd = ua.collisionData[ub.collisionId];
				if (!ucd)
					ucd = ua.collisionData[ub.collisionId] = {
						frame: this.collisionFrame,
						//touching
					};
				if (ucd.frame == this.collisionFrame)
					ua.onStartColliding && ua.onStartColliding(ub, contactManifold, threeObject0, threeObject1);
				else
					ua.onWhileColliding && ua.onWhileColliding(ub, contactManifold, threeObject0, threeObject1);
				//ucd.frame = this.collisionFrame;
				//ucd.touching = true;
			} else {
				ua.onStopColliding && ua.onStopColliding(ub);
				ua.collisionData[ub.collisionId] = undefined;
			}
		}

		this.updateContacts = (ua, ub, touching, contactManifold, threeObject0, threeObject1) => {
			this.dispatchCollision(ua, ub, touching, contactManifold, threeObject0, threeObject1)
			this.dispatchCollision(ub, ua, touching, contactManifold, threeObject0, threeObject1)
		}

		this.processCollisions = (dispatcher) => {
			this.collisionFrame++;
			for (var i = 0, il = dispatcher.getNumManifolds(); i < il; i++) {
				var contactManifold = dispatcher.getManifoldByIndexInternal(i);
				let rb0 = Ammo.castObject(contactManifold.getBody0(), Ammo.btRigidBody);
				let rb1 = Ammo.castObject(contactManifold.getBody1(), Ammo.btRigidBody);
				let threeObject0 = Ammo.castObject(rb0.getUserPointer(), Ammo.btVector3).physicsData.root;
				let threeObject1 = Ammo.castObject(rb1.getUserPointer(), Ammo.btVector3).physicsData.root;
				if (!(threeObject0 || threeObject1))
					continue;
				let userData0 = threeObject0 ? threeObject0.userData : null;
				let userData1 = threeObject1 ? threeObject1.userData : null;

				let touching = false;
				for (var j = 0, jl = contactManifold.getNumContacts(); j < jl; j++) {
					let cp = contactManifold.getContactPoint(j);
					if (cp.getDistance() < 0.0) { touching = true; break; }
				}
				this.updateContacts(userData0, userData1, touching, contactManifold, threeObject0, threeObject1)
			}
		}
		this.processCollisions1 = (dispatcher) => {

			//Process all collisions
			for (var i = 0, il = dispatcher.getNumManifolds(); i < il; i++) {
				var contactManifold = dispatcher.getManifoldByIndexInternal(i);
				var rb0 = Ammo.castObject(contactManifold.getBody0(), Ammo.btRigidBody);
				var rb1 = Ammo.castObject(contactManifold.getBody1(), Ammo.btRigidBody);

				var threeObject0 = Ammo.castObject(rb0.getUserPointer(), Ammo.btVector3).physicsData.root;
				var threeObject1 = Ammo.castObject(rb1.getUserPointer(), Ammo.btVector3).physicsData.root;

				if (!threeObject0 && !threeObject1) {
					continue;
				}

				var userData0 = threeObject0 ? threeObject0.userData.physics : null;
				var userData1 = threeObject1 ? threeObject1.userData.physics : null;

				var collided0 = userData0 ? userData0.collided : false;
				var collided1 = userData1 ? userData1.collided : false;

				var breakable0 = userData0 ? userData0.breakable : false;
				var breakable1 = userData1 ? userData1.breakable : false;

				if (collided0 && collided1) {
					continue;
				}

				let response_a
				let response_b
				if (userData0 && userData1) {
					let c0 = userData0.collisionType || 0
					let c1 = userData1.collisionType || 0
					response_a = collisionDispatcher[c0]
					if (response_a)
						response_a = response_a[c1]
					response_b = collisionDispatcher[c1]
					if (response_b)
						response_b = response_b[c0]
				}
				var contact = false;
				var maxImpulse = 0.;
				var largestImpulseIndex = 0
				let pos, normal
				let primaryContact;
				for (var j = 0, jl = contactManifold.getNumContacts(); j < jl; j++) {
					var contactPoint = contactManifold.getContactPoint(j);

					if (contactPoint.getDistance() < 0.) {
						contact = true;
						var impulse = contactPoint.getAppliedImpulse();
						if (impulse > maxImpulse) {
							maxImpulse = impulse
							largestImpulseIndex = j;
							primaryContact = contactPoint;

							pos = contactPoint.m_positionWorldOnA;
							normal = contactPoint.m_normalWorldOnB;
							impactPoint.set(pos.x(), pos.y(), pos.z());
							impactNormal.set(normal.x(), normal.y(), normal.z());

						}
					}
				}

				// If no point has contact, abort
				if (!contact)
					continue;

				pos = contactPoint.get_m_positionWorldOnA();
				normal = contactPoint.get_m_normalWorldOnB();
				impactPoint.set(pos.x(), pos.y(), pos.z());
				impactNormal.set(normal.x(), normal.y(), normal.z());
				if (response_a) {
					response_a(threeObject0, threeObject1, impactPoint, impactNormal, maxImpulse);
					//This dies if the userData.physics isn't set up correctly.
				}
				if (response_b) {
					impactNormal.multiplyScalar(-1);
					response_b(threeObject1, threeObject0, impactPoint, impactNormal, maxImpulse);
					//This dies if the userData.physics isn't set up correctly.
				}
				if (!breakable0 && !breakable1)
					continue
				// Subdivision

				var fractureImpulse = 250;

				if (breakable0 && !collided0 && maxImpulse > fractureImpulse) {
					var debris = convexBreaker.subdivideByImpact(threeObject0, impactPoint, impactNormal, 1, 2, 1.5);

					var numObjects = debris.length;
					for (var j = 0; j < numObjects; j++) {
						var vel = rb0.getLinearVelocity();
						var angVel = rb0.getAngularVelocity();
						var fragment = debris[j];
						fragment.userData.physics.velocity.set(vel.x(), vel.y(), vel.z());
						fragment.userData.physics.angularVelocity.set(angVel.x(), angVel.y(), angVel.z());

						createDebrisFromBreakableObject(fragment);
					}

					objectsToRemove[numObjectsToRemove++] = threeObject0;
					userData0.collided = true;
				}

				if (breakable1 && !collided1 && maxImpulse > fractureImpulse) {
					var debris = convexBreaker.subdivideByImpact(threeObject1, impactPoint, impactNormal, 1, 2, 1.5);

					var numObjects = debris.length;
					for (var j = 0; j < numObjects; j++) {
						var vel = rb1.getLinearVelocity();
						var angVel = rb1.getAngularVelocity();
						var fragment = debris[j];
						fragment.userData.physics.velocity.set(vel.x(), vel.y(), vel.z());
						fragment.userData.physics.angularVelocity.set(angVel.x(), angVel.y(), angVel.z());
						createDebrisFromBreakableObject(fragment);
					}

					objectsToRemove[numObjectsToRemove++] = threeObject1;
					userData1.collided = true;
				}
			}
		}
	}
}
