import { gsap } from "gsap";
import {
  activeSecondCameraFunc,
  arvrBtn,
  bitcoinTableBtn,
  disableFunc,
  enter,
  enterFunc,
  esc,
  escBtn,
  gameDevBtn,
  mobileBtn,
  nextBtn,
  page2,
  servicesPrevBtn,
  uixBtn,
  webDesignBtn,
} from "../pageTwo/interactions";
import { camera, orbitControls, secondCamera } from "../script";
import { interactionScene, player } from "./loader";

let initialEnter = true;

export const playerInteraction = () => {
  if (interactionScene) {
    /** WEBSITE DESIGNING */
    if (
      player.position.x <= -1.5 &&
      player.position.x >= -3.77 &&
      player.position.z <= -0.6 &&
      player.position.z >= -2.7
    ) {
      interactionScene.getObjectByName("Cube047").material.emissive.b = 3;
      interactionScene.getObjectByName("Cube047").material.emissive.g = 3;
      interactionScene.getObjectByName("Cube047").material.emissive.r = 3;

      if (initialEnter) {
        webDesignBtn.classList.remove("invisible");
        webDesignBtn.classList.add("visible");
        webDesignBtn.disabled = false;
        initialEnter = false;

        if (
          webDesignBtn.classList.contains("visible") &&
          webDesignBtn.classList.contains("button-add")
        ) {
          document.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              webDesignBtn.click();
              webDesignBtn.disabled = true;
            }
          });
        }
      }

      if (enter && !initialEnter) {
        disableFunc(true);
        player.visible = false;
        enterFunc(false);
        webDesignBtn.classList.remove("visible");
        gsap.to(camera.position, {
          x: -2.5,
          y: 1.12,
          z: 1.5,
          duration: 1.5,
        });

        setTimeout(() => {
          escBtn.classList.remove("invisible");
          escBtn.classList.add("visible");
        }, 1500);
      } else if (esc) {
        if (escBtn.classList.contains("visible")) {
          orbitControls.enabled = true;
          escBtn.classList.remove("visible");
          gsap.to(camera.position, {
            x: 9.3,
            y: 5.5,
            z: 10.6,
            duration: 1.5,
          });
          setTimeout(() => {
            initialEnter = true;
          }, 1500);
          setTimeout(() => {
            player.visible = true;
            disableFunc(false);
          }, 300);
        }
      }
    } else if (
      /** AR/VR */
      player.position.x >= -3.76 &&
      player.position.x <= -2.15 &&
      player.position.z >= 0.2 &&
      player.position.z <= 1.6
    ) {
      interactionScene.children[2].children.forEach(function (child) {
        if (
          child.name === "Cube092" ||
          child.name === "SK_Mannequin_LOD1001" ||
          child.name === "defaultMaterial001"
        ) {
          if (child.material) {
            child.material.emissive.r = 3;
            child.material.emissive.g = 3;
            child.material.emissive.b = 3;
          }
        }
      });

      if (initialEnter) {
        arvrBtn.classList.remove("invisible");
        arvrBtn.classList.add("visible");
        arvrBtn.disabled = false;
        initialEnter = false;

        if (
          arvrBtn.classList.contains("visible") &&
          arvrBtn.classList.contains("button-add")
        ) {
          document.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              arvrBtn.click();
              arvrBtn.disabled = true;
            }
          });
        }
      }

      if (enter && !initialEnter) {
        player.visible = false;
        disableFunc(true);
        enterFunc(false);
        arvrBtn.classList.remove("visible");
        gsap.to(camera.position, {
          x: 1,
          y: 1,
          z: 1,
          duration: 1.5,
        });

        setTimeout(() => {
          escBtn.classList.remove("invisible");
          escBtn.classList.add("visible");
        }, 1500);
      } else if (esc) {
        if (escBtn.classList.contains("visible")) {
          orbitControls.enabled = true;
          escBtn.classList.remove("visible");
          gsap.to(camera.position, {
            x: 9.3,
            y: 5.5,
            z: 10.6,
            duration: 1.5,
          });

          setTimeout(() => {
            initialEnter = true;
          }, 1500);
          setTimeout(() => {
            player.visible = true;
            disableFunc(false);
          }, 300);
        }
      }
    } else if (
      /** NEXT PAGE */
      player.position.x >= 0.6 &&
      player.position.x <= 1.25 &&
      player.position.z <= -2.15 &&
      player.position.z >= -2.55
    ) {
      if (initialEnter) {
        nextBtn.classList.remove("invisible");
        nextBtn.classList.add("visible");
        nextBtn.disabled = page2 ? false : true;
        initialEnter = false;

        if (
          nextBtn.classList.contains("visible") &&
          nextBtn.classList.contains("button-add")
        ) {
          document.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              nextBtn.click();
              nextBtn.disabled = true;
            }
          });
        }
      }
    } else if (
      player.position.x >= -0.4 &&
      player.position.x <= 0.3 &&
      player.position.z <= -2.3 &&
      player.position.z >= -3
    ) {
      if (initialEnter) {
        servicesPrevBtn.classList.remove("invisible");
        servicesPrevBtn.classList.add("visible");
        servicesPrevBtn.disabled = page2 ? false : true;
        initialEnter = false;

        if (
          servicesPrevBtn.classList.contains("visible") &&
          servicesPrevBtn.classList.contains("button-add")
        ) {
          document.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              servicesPrevBtn.click();
              servicesPrevBtn.disabled = true;
            }
          });
        }
      }
    } else if (
      /** GAME DEVELOPMENT */
      player.position.x >= 1.66 &&
      player.position.x <= 4.3 &&
      player.position.z <= -0.8 &&
      player.position.z >= -2.8
    ) {
      interactionScene.getObjectByName(
        "Game_Development"
      ).material.emissive.b = 3;
      interactionScene.getObjectByName(
        "Game_Development"
      ).material.emissive.g = 3;
      interactionScene.getObjectByName(
        "Game_Development"
      ).material.emissive.r = 3;

      if (initialEnter) {
        gameDevBtn.classList.remove("invisible");
        gameDevBtn.classList.add("visible");
        gameDevBtn.disabled = false;
        initialEnter = false;

        if (
          gameDevBtn.classList.contains("visible") &&
          gameDevBtn.classList.contains("button-add")
        ) {
          document.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              gameDevBtn.click();
              gameDevBtn.disabled = true;
            }
          });
        }
      }

      if (enter && !initialEnter) {
        player.visible = false;
        disableFunc(true);
        enterFunc(false);
        gameDevBtn.classList.remove("visible");
        gsap.to(camera.position, {
          x: 2.5,
          y: 1,
          z: 2,
          duration: 1.5,
        });

        setTimeout(() => {
          escBtn.classList.remove("invisible");
          escBtn.classList.add("visible");
        }, 1500);
      } else if (esc) {
        if (escBtn.classList.contains("visible")) {
          orbitControls.enabled = true;
          escBtn.classList.remove("visible");
          gsap.to(camera.position, {
            x: 9.3,
            y: 5.5,
            z: 10.6,
            duration: 1.5,
          });

          setTimeout(() => {
            initialEnter = true;
          }, 1500);
          setTimeout(() => {
            player.visible = true;
            disableFunc(false);
          }, 300);
        }
      }
    } else if (
      /** MOBILE DEVELOPMENT */
      player.position.x >= 2.15 &&
      player.position.x <= 4.3 &&
      player.position.z >= -0.1 &&
      player.position.z <= 1.55
    ) {
      interactionScene.getObjectByName(
        "Website_development4"
      ).material.emissive.b = 3;
      interactionScene.getObjectByName(
        "Website_development4"
      ).material.emissive.g = 3;
      interactionScene.getObjectByName(
        "Website_development4"
      ).material.emissive.r = 3;

      if (initialEnter) {
        mobileBtn.classList.remove("invisible");
        mobileBtn.classList.add("visible");
        mobileBtn.disabled = false;
        initialEnter = false;

        if (
          mobileBtn.classList.contains("visible") &&
          mobileBtn.classList.contains("button-add")
        ) {
          document.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              mobileBtn.click();
              mobileBtn.disabled = true;
            }
          });
        }
      }

      if (enter && !initialEnter) {
        player.visible = false;
        disableFunc(true);
        enterFunc(false);
        activeSecondCameraFunc(true);
        mobileBtn.classList.remove("visible");
        gsap.to(camera.position, {
          x: -0.4,
          y: 1,
          z: 0.9,
          duration: 1.5,
        });
        gsap.to(secondCamera.position, {
          x: 0.55,
          y: 0.55,
          z: 1,
          duration: 1.5,
        });
        gsap.to(secondCamera.rotation, {
          x: 0,
          y: -1.55,
          z: 0,
          duration: 2.5,
        });

        setTimeout(() => {
          escBtn.classList.remove("invisible");
          escBtn.classList.add("visible");
        }, 1500);
      } else if (esc) {
        if (escBtn.classList.contains("visible")) {
          activeSecondCameraFunc(false);
          escBtn.classList.remove("visible");
          orbitControls.enabled = true;
          gsap.to(camera.position, {
            x: 9.3,
            y: 5.5,
            z: 10.6,
            duration: 1.5,
          });

          setTimeout(() => {
            initialEnter = true;
          }, 1500);
          setTimeout(() => {
            player.visible = true;
            disableFunc(false);
          }, 300);
        }
      }
    } else if (
      /** BITCOIN */
      player.position.x >= -1.4 &&
      player.position.x <= 1.15 &&
      player.position.z >= -1.7 &&
      player.position.z <= 0.3
    ) {
      interactionScene.getObjectByName(
        "bitcoin"
      ).children[0].material.emissive.b = 3;
      interactionScene.getObjectByName(
        "bitcoin"
      ).children[0].material.emissive.g = 3;
      interactionScene.getObjectByName(
        "bitcoin"
      ).children[0].material.emissive.r = 3;
      interactionScene.getObjectByName(
        "bitcoin"
      ).children[1].material.emissive.b = 3;
      interactionScene.getObjectByName(
        "bitcoin"
      ).children[1].material.emissive.g = 3;
      interactionScene.getObjectByName(
        "bitcoin"
      ).children[1].material.emissive.r = 3;

      if (initialEnter) {
        bitcoinTableBtn.classList.remove("invisible");
        bitcoinTableBtn.classList.add("visible");
        bitcoinTableBtn.disabled = false;
        initialEnter = false;

        if (
          bitcoinTableBtn.classList.contains("visible") ||
          (bitcoinTableBtn.classList.contains("visible") &&
            bitcoinTableBtn.classList.contains("button-add"))
        ) {
          document.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              bitcoinTableBtn.click();
              bitcoinTableBtn.disabled = true;
            }
          });
        }
      }

      if (enter && !initialEnter) {
        player.visible = false;
        disableFunc(true);
        enterFunc(false);
        activeSecondCameraFunc(true);
        bitcoinTableBtn.classList.remove("visible");
        gsap.to(camera.position, {
          x: -0.25,
          y: 1,
          z: 3.2,
          duration: 1.5,
        });
        gsap.to(secondCamera.position, {
          x: -0.21,
          y: 2,
          z: 1.15,
          duration: 1.5,
        });
        gsap.to(secondCamera.rotation, {
          x: -0.75,
          y: 0,
          z: 0,
          duration: 1.5,
        });

        setTimeout(() => {
          escBtn.classList.remove("invisible");
          escBtn.classList.add("visible");
        }, 1500);
      } else if (esc) {
        if (escBtn.classList.contains("visible")) {
          setTimeout(() => {
            initialEnter = true;
          }, 1500);
          activeSecondCameraFunc(false);
          orbitControls.enabled = true;
          escBtn.classList.remove("visible");
          gsap.to(camera.position, {
            x: 9.3,
            y: 5.5,
            z: 10.6,
            duration: 1.5,
          });

          setTimeout(() => {
            player.visible = true;
            disableFunc(false);
          }, 300);
        }
      }
    } else if (
      /** UI/UX */
      player.position.x >= -1 &&
      player.position.x <= 0.45 &&
      player.position.z >= 0.5 &&
      player.position.z <= 1.6
    ) {
      interactionScene.getObjectByName("Cube073").material.emissive.b = 3;
      interactionScene.getObjectByName("Cube073").material.emissive.g = 3;
      interactionScene.getObjectByName("Cube073").material.emissive.r = 3;

      if (initialEnter) {
        uixBtn.classList.remove("invisible");
        uixBtn.classList.add("visible");
        uixBtn.disabled = false;
        initialEnter = false;

        if (
          uixBtn.classList.contains("visible") &&
          uixBtn.classList.contains("button-add")
        ) {
          document.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              uixBtn.click();
              uixBtn.disabled = true;
            }
          });
        }
      }

      if (enter && !initialEnter) {
        player.visible = false;
        disableFunc(true);
        enterFunc(false);
        activeSecondCameraFunc(true);
        uixBtn.classList.remove("visible");
        gsap.to(camera.position, {
          x: -0.25,
          y: 1,
          z: 3.2,
          duration: 1.5,
        });
        gsap.to(secondCamera.position, {
          x: -0.25,
          y: 1,
          z: 3.2,
          duration: 1.5,
        });
        gsap.to(secondCamera.rotation, {
          x: -0.4,
          y: 0,
          z: 0,
          duration: 1.5,
        });

        setTimeout(() => {
          escBtn.classList.remove("invisible");
          escBtn.classList.add("visible");
        }, 1500);
      } else if (esc) {
        if (escBtn.classList.contains("visible")) {
          setTimeout(() => {
            initialEnter = true;
          }, 1500);
          activeSecondCameraFunc(false);
          orbitControls.enabled = true;
          escBtn.classList.remove("visible");
          gsap.to(camera.position, {
            x: 9.3,
            y: 5.5,
            z: 10.6,
            duration: 1.5,
          });

          setTimeout(() => {
            player.visible = true;
            disableFunc(false);
          }, 300);
        }
      }
    } else {
      interactionScene.children[2].children.forEach(function (child) {
        if (
          child.name === "Game_Development" ||
          child.name === "Cube092" ||
          child.name === "SK_Mannequin_LOD1001" ||
          child.name === "defaultMaterial001" ||
          child.name === "Website_development4" ||
          child.name === "Cube073"
        ) {
          if (child.material) {
            child.material.emissive.r = 1;
            child.material.emissive.g = 1;
            child.material.emissive.b = 1;
          }
        }
        if (child.name === "bitcoin") {
          child.children.forEach(function (subChild) {
            subChild.material.emissive.r = 1;
            subChild.material.emissive.g = 1;
            subChild.material.emissive.b = 1;
          });
        }
      });
      interactionScene.getObjectByName("Cube047").material.emissive.b = 1;
      interactionScene.getObjectByName("Cube047").material.emissive.g = 1;
      interactionScene.getObjectByName("Cube047").material.emissive.r = 1;

      arvrBtn.classList.add("invisible");
      bitcoinTableBtn.classList.add("invisible");
      webDesignBtn.classList.add("invisible");
      nextBtn.classList.add("invisible");
      gameDevBtn.classList.add("invisible");
      mobileBtn.classList.add("invisible");
      uixBtn.classList.add("invisible");
      servicesPrevBtn.classList.add("invisible");
      escBtn.classList.add("invisible");
      arvrBtn.classList.remove("visible");
      bitcoinTableBtn.classList.remove("visible");
      webDesignBtn.classList.remove("visible");
      nextBtn.classList.remove("visible");
      gameDevBtn.classList.remove("visible");
      mobileBtn.classList.remove("visible");
      uixBtn.classList.remove("visible");
      servicesPrevBtn.classList.remove("visible");
      escBtn.classList.remove("visible");
      arvrBtn.disabled = true;
      bitcoinTableBtn.disabled = true;
      webDesignBtn.disabled = true;
      nextBtn.disabled = true;
      gameDevBtn.disabled = true;
      mobileBtn.disabled = true;
      uixBtn.disabled = true;
      servicesPrevBtn.disabled = true;
      initialEnter = true;
    }
  }
};
