import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

import { logoMixer, playAnim } from "../pageThree/ContactUsPage";
import { CharacterControls } from "../class/CharacterControls";
import {
  camera,
  doorLightMaterial,
  movementControllerDisplay,
  orbitControls,
  overlayMaterial,
  playerBody,
  scene,
  stars,
  v0,
} from "../script";
import { forwardPopUpBook, forwardPopUpPage1 } from "../pageOne/homePage";
import { gsap } from "gsap";

let animations;

// Contact Page
let scene3;
let contactAnimations;

// Interaction Page
let player;
let mixer;
let interactionScene;
let flipPageCloseAnimations;
let scenePortalInteraction;
let portalAnimationsInteraction;

// Home Page
let book;
let scene1;
let scenePortalHome;
let flipPageOpen;
let flipPageClose;
let homeAnimations;
let portalAnimations;
let bookAnimations;
let flipPageOpenAnimations;

/**
 * Loader
 */
let sceneReady = false;
const loadingBarElement = document.querySelector(".loading-bar");

const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    gsap.delayedCall(0.5, () => {
      gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0 });
      loadingBarElement.classList.add("ended");
      loadingBarElement.style.transform = "";
      scene.add(stars);
    });

    window.setTimeout(() => {
      sceneReady = true;
      document.querySelector(".websiteLink").style.display = "inline-block"
    }, 2500);
  },

  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
    const progressRatio = itemsLoaded / itemsTotal;
    loadingBarElement.style.transform = `scaleX(${progressRatio})`;
  }
);

// const progressBar = document.getElementById("progress-bar");

// loadingManager.onProgress = function (url, loaded, total) {
//   progressBar.value = (loaded / total) * 100;
// };

// const progressBarContainer = document.querySelector(".progress-bar-container");

// loadingManager.onLoad = function () {
//   progressBarContainer.style.display = "none";
// };

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("../node_modules/three/examples/js/libs/draco");

/*** Character ***/
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);

// MODEL WITH ANIMATIONS
var characterControls;
gltfLoader.load("resources/Character/Character.glb", (glb) => {
  glb.scene.traverse(function (child) {
    if (child.isMesh) {
      let m = child;
      m.receiveShadow = true;
      m.castShadow = true;
      m.frustumCulled = false;
      m.geometry.computeVertexNormals();
      if (child.material) {
        const mat = child.material;
        mat.transparent = false;
        mat.side = THREE.FrontSide;
      }
    }
  });
  glb.scene.position.set(-0.5, 0.05, -2);
  glb.scene.scale.set(1.6, 1.6, 1.6);

  const gltfAnimations = glb.animations;
  const animationsMap = new Map();
  player = glb.scene;
  mixer = new THREE.AnimationMixer(glb.scene);

  const action = mixer.clipAction(glb.animations[0]);
  action.play();
  gltfAnimations
    .filter((a) => a.name != "T Pose")
    .forEach((a) => {
      animationsMap.set(a.name, mixer.clipAction(a));
    });

  characterControls = new CharacterControls(
    player,
    mixer,
    animationsMap,
    orbitControls,
    camera,
    "idle",
    playerBody,
    v0,
    gltfAnimations
  );
});

/*** Home Page ***/
const loadingManager2 = new THREE.LoadingManager(() => {
  window.setTimeout(() => {
    forwardPopUpPage1();
    movementControllerDisplay.classList.remove("display-none");
    movementControllerDisplay.classList.add("fade-in");
  }, 2700);
  window.setTimeout(() => {
    forwardPopUpBook();
  }, 100);
});

const gltfLoader2 = new GLTFLoader(loadingManager2);

gltfLoader2.load("/resources/Scenes/Page1.glb", (glb) => {
  glb.scene.traverse((object) => (object.frustumCulled = false));
  scene1 = glb.scene;
  homeAnimations = glb.animations;
});

gltfLoader2.load("/resources/Scenes/Portal_Home.glb", (glb) => {
  glb.scene.traverse((object) => (object.frustumCulled = false));
  scenePortalHome = glb.scene;
  portalAnimations = glb.animations;

  const doorLightMesh = glb.scene.children.find(
    (child) => child.name === "PORTAL1"
  );
  doorLightMesh.material = doorLightMaterial;
});

gltfLoader2.load("/resources/Scenes/Book.glb", (glb) => {
  glb.scene.traverse((object) => (object.frustumCulled = false));
  book = glb.scene;
  bookAnimations = glb.animations;
});

gltfLoader2.load("/resources/Scenes/OpenPage.glb", (glb) => {
  glb.scene.traverse((object) => (object.frustumCulled = false));
  flipPageOpen = glb.scene;
  flipPageOpenAnimations = glb.animations;
});

/*** Interaction Page ***/
gltfLoader.load("/resources/Scenes/Page2.glb", (glb) => {
  interactionScene = glb.scene;
  animations = glb.animations;

  glb.scene.traverse((object) => (object.frustumCulled = false));

  interactionScene.scale.set(2, 2, 2);
  interactionScene.position.set(2.29, -1.495, 1.98);
  interactionScene.rotation.set(0, 4.72, 0);
});

gltfLoader2.load("/resources/Scenes/Portal_Service.glb", (glb) => {
  glb.scene.traverse((object) => (object.frustumCulled = false));
  scenePortalInteraction = glb.scene;
  portalAnimationsInteraction = glb.animations;

  scenePortalInteraction.scale.set(2, 2, 2);
  scenePortalInteraction.position.set(2.29, -1.495, 1.95);
  scenePortalInteraction.rotation.set(0, 4.72, 0);

  const doorLightMesh = glb.scene.children.find(
    (child) => child.name === "PORTAL_SERVICE003"
  );
  doorLightMesh.material = doorLightMaterial;
});

gltfLoader.load("/resources/Scenes/ClosingPage.glb", (glb) => {
  glb.scene.traverse((object) => (object.frustumCulled = false));
  flipPageClose = glb.scene;
  flipPageCloseAnimations = glb.animations;
});

/*** Contact Page ***/
loadingManager.onStart = () => {
  setTimeout(() => {
    logoMixer = new THREE.AnimationMixer(scene3);
    if (contactAnimations) {
      contactAnimations.forEach((clip) => {
        playAnim = logoMixer.clipAction(clip);
        playAnim.reset();
        playAnim.clampWhenFinished = true;
        playAnim.setLoop(THREE.LoopOnce, 1);
        playAnim.timeScale = 1;
        playAnim.play();
      });
    }
  }, 2000);
};

gltfLoader.load("/resources/Scenes/Page3.glb", (glb) => {
  glb.scene.traverse((object) => (object.frustumCulled = false));
  glb.scene.scale.set(2, 2, 2);
  glb.scene.position.set(2, -1.584, 2);
  glb.scene.rotation.set(0, 4.72, 0);

  scene3 = glb.scene;
  contactAnimations = glb.animations;
});

export {
  book,
  mixer,
  player,
  scene3,
  scene1,
  sceneReady,
  animations,
  scenePortalHome,
  flipPageOpen,
  flipPageClose,
  bookAnimations,
  homeAnimations,
  portalAnimations,
  interactionScene,
  contactAnimations,
  characterControls,
  scenePortalInteraction,
  flipPageOpenAnimations,
  flipPageCloseAnimations,
  portalAnimationsInteraction,
};
