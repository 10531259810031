import * as THREE from "three";
import { scene, camera, firefliesUD, firefliesLR } from "../script";
import { forwardPopUpPage2 } from "../pageTwo/interactions";
import { gsap } from "gsap";
import {
  addPage2Physics,
  removePage1Physics,
} from "../Utils/physicsAdd-Remove";
import {
  book,
  bookAnimations,
  flipPageOpen,
  flipPageOpenAnimations,
  homeAnimations,
  portalAnimations,
  player,
  scene1,
  scenePortalHome,
} from "../Utils/loader";

let homePageMixer = null;
let portalMixer = null;
let playAnim = null;
let playAnimPortal = null;

let bookAnimMixer = null;
let playAnimBook = null;

let flipPageOpenAnimMixer = null;
let playAnimFlipPageOpen = null;

let page1 = true;
let disableHomeToScene1 = false;

const homeNextBtn = document.querySelector(".homeNext");
homeNextBtn.disabled = true;
homeNextBtn.addEventListener("click", () => {
  homeNextBtn.classList.remove("visible");
  removePage1Btns();
  removePage1Physics();
  addPage2Physics();
  reversePopUp();
  disableHomeToScene1 = true;
  document.getElementById("arrow-enter").classList.remove("visible");
});

const addPage1Btns = () => {
  homeNextBtn.classList.add("button-add");
};

const removePage1Btns = () => {
  homeNextBtn.classList.remove("button-add");
  homeNextBtn.disabled = true;
};

const homePage = () => {
  // if (player.position.x <= 0.8 && player.position.x >= -1.1) {
  //   gsap.to(camera.position, {
  //     x: 0,
  //     y: 2,
  //     z: 10,
  //     duration: 2,
  //   });
  // } else if (
  //   player.position.x <= -1.11 &&
  //   player.position.x >= -4 &&
  //   player.position.z <= 1.6 &&
  //   player.position.z >= -2.7
  // ) {
  //   gsap.to(camera.position, {
  //     x: -6,
  //     y: 2,
  //     z: 7,
  //     duration: 2,
  //   });
  // } else {
  //   gsap.to(camera.position, {
  //     x: 3,
  //     y: 2,
  //     z: 10,
  //     duration: 2,
  //   });
  // }
  if (
    /** NEXT PAGE */
    player.position.x >= 0.93 &&
    player.position.x <= 1.2 &&
    player.position.z >= -1.12 &&
    player.position.z <= -0.42
  ) {
    homeNextBtn.classList.remove("invisible");
    homeNextBtn.classList.add("visible");
    homeNextBtn.disabled = page1 ? false : true;

    if (
      homeNextBtn.classList.contains("visible") &&
      homeNextBtn.classList.contains("button-add")
    ) {
      document.addEventListener("keypress", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
          homeNextBtn.click();
          homeNextBtn.disabled = true;
        }
      });
    }
  } else {
    homeNextBtn.classList.remove("visible");
    homeNextBtn.classList.add("invisible");
    homeNextBtn.disabled = true;
  }
};

const forwardPopUpPage1 = () => {
  if (player) {
    player.visible = true;
    disableHomeToScene1 = false;
    player.scale.set(1.6, 1.6, 1.6);
    page1 = true;
    homePageMixer = new THREE.AnimationMixer(scene1);
    portalMixer = new THREE.AnimationMixer(scenePortalHome);
    addPage1Btns();
    homeNextBtn.disabled = false;
    gsap.to(camera.position, {
      x: 9.3,
      y: 5.5,
      z: 10.6,
      duration: 1.5,
    });

    if (homeAnimations) {
      homeAnimations.forEach((clip) => {
        playAnim = homePageMixer.clipAction(clip);
        playAnim.reset();
        playAnim.clampWhenFinished = true;
        playAnim.setLoop(THREE.LoopOnce, 1);
        playAnim.timeScale = 1 / 3;
        playAnim.play();
      });
    }
    if (portalAnimations) {
      portalAnimations.forEach((clip) => {
        playAnimPortal = portalMixer.clipAction(clip);
        playAnimPortal.reset();
        playAnimPortal.clampWhenFinished = true;
        playAnimPortal.setLoop(THREE.LoopOnce, 1);
        playAnimPortal.timeScale = 1 / 3;
        playAnimPortal.play();
      });
    }
    scene1.scale.set(2, 2, 2);
    scene1.rotation.set(0, 4.72, 0);
    scene1.position.set(2, -1.817, 2);
    scenePortalHome.scale.set(2, 2, 2);
    scenePortalHome.rotation.set(0, 4.72, 0);
    scenePortalHome.position.set(2.01, -1.817, 2);

    scene.add(player);
    scene.add(scene1);
    scene.add(scenePortalHome);
    scene.add(firefliesUD);
    scene.add(firefliesLR);
  }
};

const forwardPopUpPage1FromPage2 = () => {
  if (player) {
    player.visible = true;
    disableHomeToScene1 = false;
    player.scale.set(1.6, 1.6, 1.6);
    page1 = true;
    homePageMixer = new THREE.AnimationMixer(scene1);
    portalMixer = new THREE.AnimationMixer(scenePortalHome);
    addPage1Btns();
    homeNextBtn.disabled = false;
    gsap.to(camera.position, {
      x: 9.3,
      y: 5.5,
      z: 10.6,
      duration: 1.5,
    });

    scene.add(player);
    scene.remove(flipPageOpen);
    setTimeout(() => {
      scene.add(scene1);
      scene.add(scenePortalHome);
      scene.add(firefliesUD);
      scene.add(firefliesLR);
      if (homeAnimations) {
        homeAnimations.forEach((clip) => {
          playAnim = homePageMixer.clipAction(clip);
          playAnim.reset();
          playAnim.clampWhenFinished = true;
          playAnim.setLoop(THREE.LoopOnce, 1);
          playAnim.timeScale = 1 / 3;
          playAnim.play();
        });
      }
      if (portalAnimations) {
        portalAnimations.forEach((clip) => {
          playAnimPortal = portalMixer.clipAction(clip);
          playAnimPortal.reset();
          playAnimPortal.clampWhenFinished = true;
          playAnimPortal.setLoop(THREE.LoopOnce, 1);
          playAnimPortal.timeScale = 1 / 3;
          playAnimPortal.play();
        });
      }
      scene1.scale.set(2, 2, 2);
      scene1.rotation.set(0, 4.72, 0);
      scene1.position.set(2, -1.817, 2);
      scenePortalHome.scale.set(2, 2, 2);
      scenePortalHome.rotation.set(0, 4.72, 0);
      scenePortalHome.position.set(2.01, -1.817, 2);
    }, 500);
  }
};

const forwardPopUpBook = () => {
  bookAnimMixer = new THREE.AnimationMixer(book);
  if (bookAnimations) {
    bookAnimations.forEach((clip) => {
      playAnimBook = bookAnimMixer.clipAction(clip);
      playAnimBook.reset();
      playAnimBook.clampWhenFinished = true;
      playAnimBook.setLoop(THREE.LoopOnce, 1);
      playAnimBook.timeScale = 1 / 0.65;
      playAnimBook.play();
    });
  }

  book.scale.set(2, 2, 2);
  book.rotation.set(0, 4.72, 0);
  book.position.set(2, -1.8, 2);

  const bookGroup = new THREE.Group();
  bookGroup.add(book);

  // For 1
  bookGroup.position.set(0.31, 0.15, -0.4);

  // // For 2
  // bookGroup.position.set(0.31, 0.3, -0.4);

  // // For 3
  // bookGroup.position.set(0.31, 0.35, -0.4);

  bookGroup.rotation.set(0, 0, 0.03);
  scene.add(bookGroup);
};

const forwardPopUpFlipPageOpen = () => {
  flipPageOpenAnimMixer = new THREE.AnimationMixer(flipPageOpen);
  flipPageOpenAnimations.forEach((clip) => {
    if (clip.name === "open") {
      playAnimFlipPageOpen = flipPageOpenAnimMixer.clipAction(clip);
      playAnimFlipPageOpen.reset();
      playAnimFlipPageOpen.clampWhenFinished = true;
      playAnimFlipPageOpen.setLoop(THREE.LoopOnce, 1);
      playAnimFlipPageOpen.timeScale = 1;
      playAnimFlipPageOpen.play();
    }
  });

  flipPageOpen.scale.set(2, 2, 2);
  flipPageOpen.rotation.set(0, 4.72, 0);
  flipPageOpen.position.set(2, -1.55, 2);

  scene.add(flipPageOpen);
};

const reversePopUp = () => {
  player.visible = false;
  homeNextBtn.disabled = true;
  homeAnimations.forEach((clip) => {
    const action = homePageMixer.clipAction(clip);
    action.paused = false;
    action.setLoop(THREE.LoopOnce, 1);
    action.timeScale = -1 / 3;
    action.clampWhenFinished = true;
    action.play();
  });
  portalAnimations.forEach((clip) => {
    const action = portalMixer.clipAction(clip);
    action.paused = false;
    action.setLoop(THREE.LoopOnce, 1);
    action.timeScale = -1 / 3;
    action.clampWhenFinished = true;
    action.play();
  });

  scene.remove(flipPageOpen);
  page1 = false;
  setTimeout(() => {
    scene.remove(scene1);
    scene.remove(scenePortalHome);
    scene.remove(firefliesUD);
    scene.remove(firefliesLR);
    forwardPopUpFlipPageOpen();
    forwardPopUpPage2();
    disableHomeToScene1 = false;
  }, 2000);
};

export {
  page1,
  portalMixer,
  homePageMixer,
  bookAnimMixer,
  disableHomeToScene1,
  flipPageOpenAnimMixer,
  homePage,
  forwardPopUpPage1,
  forwardPopUpBook,
  forwardPopUpPage1FromPage2,
};
