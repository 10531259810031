import { AmmoDebugDrawer, AmmoDebugConstants, DefaultBufferSize } from "./AmmoDebugDrawer.js";

export default class PhysicsDebugger {
	init() {
		let { THREE, Ammo, scene, world } = this.params;
		let debugVertices = new Float32Array(DefaultBufferSize);
		let debugColors = new Float32Array(DefaultBufferSize);
		let debugGeometry = new THREE.BufferGeometry();
		debugGeometry.setAttribute("position", new THREE.BufferAttribute(debugVertices, 3).setUsage(THREE.DynamicDrawUsage));
		debugGeometry.setAttribute("color", new THREE.BufferAttribute(debugColors, 3).setUsage(THREE.DynamicDrawUsage));
		var debugMaterial = new THREE.LineBasicMaterial({
			vertexColors: THREE.VertexColors,
			depthWrite: false,
			transparent: true,
			opacity: .7,
			blending: THREE.AdditiveBlending
		});
		let debugMesh = new THREE.LineSegments(debugGeometry, debugMaterial);
		debugMesh.renderOrder = 10;
		debugMesh.frustumCulled = false;
		debugMesh.geometry.drawRange.count = 0;
		debugMesh.geometry.boundingBox = new THREE.Box3();
		debugMesh.geometry.boundingSphere = new THREE.Sphere();
		scene.add(debugMesh);
		let debugDrawer = this.debugDrawer = new AmmoDebugDrawer(null, debugVertices, debugColors, world, {
			Ammo
		});
		debugDrawer.disable();
		debugDrawer.setDebugMode(AmmoDebugConstants.MAX_DEBUG_DRAW_MODE)
		debugDrawer.setDebugMode(AmmoDebugConstants.DrawContactPoints || AmmoDebugConstants.DrawWireframe)
		this.updateDebugger = () => {
			if (debugDrawer && debugDrawer.enabled) {
				debugDrawer.update()
				debugMesh.geometry.drawRange.count = debugDrawer.index;
				if (debugDrawer.index) {
					debugMesh.geometry.attributes.position.updateRange.count = debugDrawer.index * 3;
					debugMesh.geometry.attributes.color.updateRange.count = debugDrawer.index * 3;
					debugMesh.geometry.attributes.position.needsUpdate = true;
					debugMesh.geometry.attributes.color.needsUpdate = true;
				}
			}
		}
	}
	update() {
		this.updateDebugger && this.updateDebugger()
	}
	activate() {
		let { renderer, scene, world } = this.params;
		if (!this.debugDrawer) {
			this.init(scene, world);
			let debugDom = this.debugDom = document.createElement('div')
			debugDom.id = 'divPhysicsDebuggerMenu'
			debugDom.style.fontFamily = 'play'
			debugDom.style.position = 'relative'

			for (let f in AmmoDebugConstants)
				debugDom.innerHTML += `<div><input type="checkbox">${f}</input></div>`
			let elems = new Array(...debugDom.getElementsByTagName('INPUT'))

			let debugFlags = 0;
			let { debugDrawer } = this;
			function toggleDebug(e) {
				let cb = e.target
				let val = cb.nextSibling.data;
				let field = AmmoDebugConstants[val]
				if (field !== undefined) {
					if (field == 0) {
						let checked = cb.checked
						elems.forEach(e => e.checked = false);
						debugDrawer.enabled = !checked;
						cb.checked = checked;
						debugFlags = 0;
					} else {
						if (cb.checked)
							debugFlags |= (field | 0);
						else
							debugFlags &= ~(field | 0);
						debugDrawer.enabled = (debugFlags) ? true : false
					}
					debugDrawer.setDebugMode(debugFlags);
				}
			}
			elems.forEach(e => e.onclick = toggleDebug);
		}
		var gameMenuElement = document.getElementById('divPhysicsDebuggerContent');
		if(gameMenuElement){
			
			gameMenuElement.appendChild(this.debugDom);
		}else{
			this.debugDom.style.position = 'absolute'
			this.debugDom.style.top = '300px'
			this.debugDom.style.zIndex = 25;
			document.body.appendChild(this.debugDom)
		}
		this.debugActive = true;
	}
	constructor(params) {
		this.Ammo = params.Ammo;
		this.THREE = params.THREE
		this.params = params;
		this.debugActive = false;
		let { renderer, scene, world } = params;
		this.renderDom = renderer.domElement;
		this.activate();
	}
}
