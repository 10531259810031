import {
  makeBoxCollider,
  makeMeshCollider,
  removeBoxCollider,
} from "../script";
import {
  sofa1,
  sofa2,
  slate,
  bush1,
  bush2,
  house,
  ar_vr,
  ui_ux,
  pillar1,
  pillar2,
  webDes1,
  webDes2,
  facebook,
  cupboard,
  linkedIn,
  messages,
  twigWall,
  doorWall,
  leftWall,
  backWall1,
  backWall2,
  rightWall,
  telephone,
  smallBush,
  mobileDev,
  frontBoxes,
  rightBoxes,
  roundBush1,
  roundBush2,
  roundBush3,
  roundBush4,
  roundBush5,
  frontBlock1,
  frontBlock2,
  frontBlock3,
  frontBlock4,
  consoleGaming,
  page1BackWall,
  page3LeftWall,
  page3BackWall,
  page3RightWall,
  page1RightWall,
  leftController,
  rightController,
  architectDesign,
  extremeRightTree,
} from "./physicsBodies";

const addPage1Physics = () => {
  makeMeshCollider(house, true);
  makeBoxCollider(frontBlock1, true);
  makeBoxCollider(frontBlock2, true);
  makeBoxCollider(frontBlock3, true);
  makeBoxCollider(frontBlock4, true);
  makeMeshCollider(page1RightWall, true);
  makeBoxCollider(roundBush1, true);
  makeBoxCollider(roundBush2, true);
  makeBoxCollider(roundBush3, true);
  makeBoxCollider(roundBush4, true);
  makeBoxCollider(roundBush5, true);
  makeBoxCollider(bush1, true);
  makeBoxCollider(bush2, true);
  makeBoxCollider(smallBush, true);
  makeMeshCollider(twigWall, true);
  makeBoxCollider(slate, true);
  makeMeshCollider(page1BackWall, true);
  makeMeshCollider(leftWall, true);
  makeMeshCollider(extremeRightTree, true);
};

const removePage1Physics = () => {
  removeBoxCollider(house);
  removeBoxCollider(frontBlock1);
  removeBoxCollider(frontBlock2);
  removeBoxCollider(frontBlock3);
  removeBoxCollider(frontBlock4);
  removeBoxCollider(page1RightWall);
  removeBoxCollider(roundBush1);
  removeBoxCollider(roundBush2);
  removeBoxCollider(roundBush3);
  removeBoxCollider(roundBush4);
  removeBoxCollider(roundBush5);
  removeBoxCollider(bush1);
  removeBoxCollider(bush2);
  removeBoxCollider(smallBush);
  removeBoxCollider(twigWall);
  removeBoxCollider(slate);
  removeBoxCollider(page1BackWall);
  removeBoxCollider(leftWall);
  removeBoxCollider(extremeRightTree);
};

const addPage2Physics = () => {
  makeBoxCollider(consoleGaming, true);
  makeBoxCollider(sofa1, true);
  makeBoxCollider(sofa2, true);
  makeBoxCollider(mobileDev, true);
  makeBoxCollider(webDes1, true);
  makeBoxCollider(webDes2, true);
  makeBoxCollider(architectDesign, true);
  makeBoxCollider(ar_vr, true);
  makeBoxCollider(ui_ux, true);
  makeBoxCollider(pillar1, true);
  makeBoxCollider(pillar2, true);
  makeBoxCollider(backWall1, true);
  makeBoxCollider(backWall2, true);
  makeBoxCollider(rightController, true);
  makeMeshCollider(leftController, true);
  makeMeshCollider(rightWall, true);
  makeMeshCollider(doorWall, true);
  makeMeshCollider(leftWall, true);
};

const removePage2Physics = () => {
  removeBoxCollider(consoleGaming);
  removeBoxCollider(sofa1);
  removeBoxCollider(sofa2);
  removeBoxCollider(mobileDev);
  removeBoxCollider(webDes1);
  removeBoxCollider(webDes2);
  removeBoxCollider(architectDesign);
  removeBoxCollider(ar_vr);
  removeBoxCollider(ui_ux);
  removeBoxCollider(pillar1);
  removeBoxCollider(pillar2);
  removeBoxCollider(backWall1);
  removeBoxCollider(backWall2);
  removeBoxCollider(rightController);
  removeBoxCollider(leftController);
  removeBoxCollider(rightWall);
  removeBoxCollider(doorWall);
  removeBoxCollider(leftWall);
};

const addPage3Physics = () => {
  makeBoxCollider(linkedIn, true);
  makeBoxCollider(messages, true);
  makeBoxCollider(telephone, true);
  makeBoxCollider(facebook, true);
  makeBoxCollider(cupboard, true);
  makeBoxCollider(frontBoxes, true);
  makeBoxCollider(rightBoxes, true);
  makeMeshCollider(page3RightWall, true);
  makeMeshCollider(page3LeftWall, true);
  makeMeshCollider(page3BackWall, true);
};

const removePage3Physics = () => {
  removeBoxCollider(linkedIn);
  removeBoxCollider(messages);
  removeBoxCollider(telephone);
  removeBoxCollider(facebook);
  removeBoxCollider(cupboard);
  removeBoxCollider(frontBoxes);
  removeBoxCollider(rightBoxes);
  removeBoxCollider(page3RightWall);
  removeBoxCollider(page3LeftWall);
  removeBoxCollider(page3BackWall);
};

export {
  addPage1Physics,
  addPage2Physics,
  addPage3Physics,
  removePage1Physics,
  removePage2Physics,
  removePage3Physics,
};
