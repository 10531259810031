import * as THREE from "three";
import {
  A,
  D,
  DIRECTIONS,
  S,
  W,
  LEFT,
  RIGHT,
  UP,
  DOWN,
  SHIFTKeyPRESSED,
} from "../utils";
import { playerBody } from "../script";
import { prevPage1 } from "../pageTwo/interactions";
import { page1 } from "../pageOne/homePage";
import { page2 } from "../pageTwo/interactions";
import { page3 } from "../pageThree/ContactUsPage";

class CharacterControls {
  constructor(
    model,
    mixer,
    animationsMap,
    orbitControl,
    camera,
    currentAction,
    playerBody,
    v0,
    gltfAnimations
  ) {
    this.animationsMap = new Map(); // Walk, Run, Idle
    // state
    this.toggleRun = false;
    // temporary data
    this.walkDirection = new THREE.Vector3();
    this.rotateAngle = new THREE.Vector3(0, 1, 0);
    this.rotateQuarternion = new THREE.Quaternion();
    this.cameraTarget = new THREE.Vector3();
    // constants
    this.fadeDuration = 0.2;
    this.runVelocity = 1.25;
    this.walkVelocity = 0.75;
    this.model = model;
    this.mixer = mixer;
    this.animationsMap = animationsMap;
    this.currentAction = currentAction;
    this.playerBody = playerBody;
    this.v0 = v0;
    this.gltfAnimations = gltfAnimations;
    this.animationsMap.forEach((value, key) => {
      if (key == currentAction) {
        value.play();
      }
    });
    this.orbitControl = orbitControl;
    this.camera = camera;
  }
  switchRunToggleTrue() {
    this.toggleRun = true;
  }
  switchRunToggleFalse() {
    this.toggleRun = false;
  }
  update(delta, keysPressed) {
    const directionPressed = DIRECTIONS.some((key) => keysPressed[key] == true);
    const shiftKeyPressed = SHIFTKeyPRESSED.some(
      (key) => keysPressed[key] == true
    );

    let play = "";
    if (directionPressed && (this.toggleRun || shiftKeyPressed)) {
      play = "Run";
    } else if (directionPressed) {
      play = "Walk";
    } else {
      play = "idle";
    }
    if (this.currentAction != play) {
      const toPlay = this.animationsMap.get(play);
      const current = this.animationsMap.get(this.currentAction);
      current.fadeOut(this.fadeDuration);
      toPlay.reset().fadeIn(this.fadeDuration).play();
      this.currentAction = play;
    }
    this.mixer.update(delta);
    if (this.currentAction == "Run" || this.currentAction == "Walk") {
      if (this.model) {
        // calculate towards camera direction
        var angleYCameraDirection = Math.atan2(
          this.camera.position.x - this.model.position.x,
          this.camera.position.z - this.model.position.z
        );

        // diagonal movement angle offset
        var directionOffset;
        directionOffset = this.directionOffset(keysPressed);

        // rotate model
        this.rotateQuarternion.setFromAxisAngle(
          this.rotateAngle,
          angleYCameraDirection + directionOffset
        );
        this.model.quaternion.rotateTowards(this.rotateQuarternion, 10);
        // calculate direction
        this.camera.getWorldDirection(this.walkDirection);
        this.walkDirection.y = 0;
        this.walkDirection.normalize();
        this.walkDirection.applyAxisAngle(this.rotateAngle, directionOffset);
        // run/walk velocity
        const velocity =
          this.currentAction == "Run" ? this.runVelocity : this.walkVelocity;

        // move model & camera
        const moveX = this.walkDirection.x * velocity * delta;
        const moveZ = this.walkDirection.z * velocity * delta;

        this.model.position.x += moveX;
        this.model.position.z += moveZ;

        const controlObject = this.model;

        const forward = new THREE.Vector3(0, 0, 1);
        forward.applyQuaternion(controlObject.quaternion);
        forward.normalize();

        forward.multiplyScalar(velocity * delta);

        if (playerBody) {
          forward.multiplyScalar(100);
          playerBody.getLinearVelocity(this.v0);
          this.v0.set(forward.x, this.v0.y, forward.z);
          playerBody.setLinearVelocity(this.v0);
          controlObject.position.copy(playerBody.root.position);

          if ((page1 && !page2) || prevPage1 || page3) {
            controlObject.position.y -= 0.8;
          } else if (page2) {
            controlObject.position.y -= 0.6;
          }
        }
      }
    }

    if (this.currentAction === "idle") {
      this.v0.set(0, 0, 0);
      playerBody.setLinearVelocity(this.v0);
    }

    if (this.currentAction == "Walknotinplace") {
      if (this.model) {
        // calculate towards camera direction
        var angleYCameraDirection = Math.atan2(
          this.camera.position.x - this.model.position.x,
          this.camera.position.z - this.model.position.z
        );

        // rotate model
        this.rotateQuarternion.setFromAxisAngle(
          this.rotateAngle,
          angleYCameraDirection + 0
        );
        this.model.quaternion.rotateTowards(this.rotateQuarternion, 10);
        // calculate direction
        this.camera.getWorldDirection(this.walkDirection);
        this.walkDirection.y = 0;
        this.walkDirection.normalize();
        this.walkDirection.applyAxisAngle(this.rotateAngle, directionOffset);
        // run/walk velocity
        const velocity =
          this.currentAction == "Run" ? this.runVelocity : this.walkVelocity;

        // move model & camera
        const moveX = this.walkDirection.x * velocity * delta;
        const moveZ = this.walkDirection.z * velocity * delta;

        this.model.position.x += moveX;
        this.model.position.z += moveZ;

        const controlObject = this.model;

        const forward = new THREE.Vector3(0, 0, 1);
        forward.applyQuaternion(controlObject.quaternion);
        forward.normalize();

        forward.multiplyScalar(velocity * delta);

        if (playerBody) {
          forward.multiplyScalar(100);
          playerBody.getLinearVelocity(this.v0);
          this.v0.set(forward.x, this.v0.y, forward.z);
          playerBody.setLinearVelocity(this.v0);

          controlObject.position.copy(playerBody.root.position);
          if ((page1 && !page2) || prevPage1 || page3) {
            controlObject.position.y -= 0.8;
          } else if (page2) {
            controlObject.position.y -= 0.6;
          }
        }
      }
    }
  }

  directionOffset(keysPressed) {
    var directionOffset = 0; // w

    if (keysPressed[W] || keysPressed[UP]) {
      if (keysPressed[A] || keysPressed[LEFT]) {
        directionOffset = -Math.PI / 4 - Math.PI / 2; // w+a || up+left
      } else if (keysPressed[D] || keysPressed[RIGHT]) {
        directionOffset = Math.PI / 4 + Math.PI / 2; // w+d || up+right
      } else {
        directionOffset = -Math.PI; // w || up
      }
    } else if (keysPressed[S] || keysPressed[DOWN]) {
      if (keysPressed[A] || keysPressed[LEFT]) {
        directionOffset = -Math.PI / 4; // s+a || down+left
      } else if (keysPressed[D] || keysPressed[RIGHT]) {
        directionOffset = Math.PI / 4; // s+d || down+right
      }
    } else if (keysPressed[A] || keysPressed[LEFT]) {
      directionOffset = -Math.PI / 2; // a || left
    } else if (keysPressed[D] || keysPressed[RIGHT]) {
      directionOffset = Math.PI / 2; // d || right
    }

    return directionOffset;
  }
}

export { CharacterControls };
