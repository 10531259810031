import * as THREE from "three";

import {
  bookAnimMixer,
  disableHomeToScene1,
  flipPageOpenAnimMixer,
  homePage,
  homePageMixer,
  portalMixer,
  page1,
} from "../pageOne/homePage";
import {
  contactPageInteractions,
  disableScene1ToContact,
  logoMixer,
  page3,
  prevPage2,
} from "../pageThree/ContactUsPage";
import {
  activeSecondCamera,
  disable,
  flipPageCloseAnimMixer,
  orbitControlsDisable,
  page2,
  portalMixerInteraction,
  prevPage1,
  textMixer,
} from "../pageTwo/interactions";
import {
  ammoPhysics,
  camera,
  doorLightMaterial,
  firefliesMaterialUD,
  firefliesMaterialLR,
  joyManager,
  keysPressed,
  orbitControls,
  playerBody,
  renderer,
  scene,
  secondCamera,
  stars,
  stats,
  v0,
  raycaster,
  pointsHome,
} from "../script";
import { playerInteraction } from "./playerInteraction";
import { characterControls, mixer, player, scene1, sceneReady } from "./loader";

// ANIMATE
const clock = new THREE.Clock();
let oldElapsedTime = 0;
let forward;
let decceleration = new THREE.Vector3(-0.0005, -0.0001, -5.0);
let velocity = new THREE.Vector3(0, 0, 0);

export function animate() {
  stats.begin();

  let mixerUpdateDelta = clock.getDelta();

  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - oldElapsedTime;
  oldElapsedTime = elapsedTime;

  const frameDecceleration = new THREE.Vector3(
    velocity.x * decceleration.x,
    velocity.y * decceleration.y,
    velocity.z * decceleration.z
  );
  frameDecceleration.multiplyScalar(deltaTime);
  frameDecceleration.z =
    Math.sign(frameDecceleration.z) *
    Math.min(Math.abs(frameDecceleration.z), Math.abs(velocity.z));

  velocity.add(frameDecceleration);

  const oldPosition = new THREE.Vector3();

  if (joyManager) {
    joyManager.on("dir:up", (evt, data) => {
      keysPressed["keyw"] = true;
      keysPressed["keya"] = false;
      keysPressed["keys"] = false;
      keysPressed["keyd"] = false;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
    });
    joyManager.on("dir:right", (evt, data) => {
      keysPressed["keyw"] = false;
      keysPressed["keya"] = false;
      keysPressed["keys"] = false;
      keysPressed["keyd"] = true;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
    });
    joyManager.on("dir:left", (evt, data) => {
      keysPressed["keyw"] = false;
      keysPressed["keya"] = true;
      keysPressed["keys"] = false;
      keysPressed["keyd"] = false;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
    });
    joyManager.on("dir:down", (evt, data) => {
      keysPressed["keyw"] = false;
      keysPressed["keya"] = false;
      keysPressed["keys"] = true;
      keysPressed["keyd"] = false;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
    });
    joyManager.on("dir:up", (evt, data) => {
      keysPressed["keyw"] = true;
      keysPressed["keya"] = false;
      keysPressed["keys"] = false;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
      joyManager.on("plain:right", (evt, data) => {
        keysPressed["keyd"] = true;
        if (data.distance > 30) keysPressed["shiftleft"] = true;
        else keysPressed["shiftleft"] = false;
      });
    });
    joyManager.on("dir:up", (evt, data) => {
      keysPressed["keyw"] = true;
      keysPressed["keys"] = false;
      keysPressed["keyd"] = false;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
      joyManager.on("plain:left", (evt, data) => {
        keysPressed["keya"] = true;
        if (data.distance > 30) keysPressed["shiftleft"] = true;
        else keysPressed["shiftleft"] = false;
      });
    });
    joyManager.on("dir:down", (evt, data) => {
      keysPressed["keyw"] = false;
      keysPressed["keya"] = false;
      keysPressed["keys"] = true;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
      joyManager.on("plain:right", (evt, data) => {
        keysPressed["keyd"] = true;
        if (data.distance > 30) keysPressed["shiftleft"] = true;
        else keysPressed["shiftleft"] = false;
      });
    });
    joyManager.on("dir:down", (evt, data) => {
      keysPressed["keyw"] = false;
      keysPressed["keys"] = true;
      keysPressed["keyd"] = false;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
      joyManager.on("plain:left", (evt, data) => {
        keysPressed["keya"] = true;
        if (data.distance > 30) keysPressed["shiftleft"] = true;
        else keysPressed["shiftleft"] = false;
      });
    });
    joyManager.on("dir:right", (evt, data) => {
      keysPressed["keyd"] = true;
      keysPressed["keya"] = false;
      keysPressed["keys"] = false;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
      joyManager.on("plain:up", (evt, data) => {
        keysPressed["keyw"] = true;
        if (data.distance > 30) keysPressed["shiftleft"] = true;
        else keysPressed["shiftleft"] = false;
      });
    });
    joyManager.on("dir:right", (evt, data) => {
      keysPressed["keyw"] = false;
      keysPressed["keya"] = false;
      keysPressed["keyd"] = true;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
      joyManager.on("plain:down", (evt, data) => {
        keysPressed["keys"] = true;
        if (data.distance > 30) keysPressed["shiftleft"] = true;
        else keysPressed["shiftleft"] = false;
      });
    });
    joyManager.on("dir:left", (evt, data) => {
      keysPressed["keya"] = true;
      keysPressed["keys"] = false;
      keysPressed["keyd"] = false;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
      joyManager.on("plain:up", (evt, data) => {
        keysPressed["keyw"] = true;
        if (data.distance > 30) keysPressed["shiftleft"] = true;
        else keysPressed["shiftleft"] = false;
      });
    });
    joyManager.on("dir:left", (evt, data) => {
      keysPressed["keyw"] = false;
      keysPressed["keya"] = true;
      keysPressed["keyd"] = false;
      if (data.distance > 30) keysPressed["shiftleft"] = true;
      else keysPressed["shiftleft"] = false;
      joyManager.on("plain:down", (evt, data) => {
        keysPressed["keys"] = true;
        if (data.distance > 30) keysPressed["shiftleft"] = true;
        else keysPressed["shiftleft"] = false;
      });
    });
    joyManager.on("end", (evt, data) => {
      keysPressed["keyw"] = false;
      keysPressed["keya"] = false;
      keysPressed["keys"] = false;
      keysPressed["keyd"] = false;
      keysPressed["shiftleft"] = false;
    });
  }

  if (player) {
    const controlObject = player;
    const _R = controlObject.quaternion.clone();

    controlObject.quaternion.copy(_R);
    oldPosition.copy(controlObject.position);

    forward = new THREE.Vector3(0, 0, 1);
    forward.applyQuaternion(controlObject.quaternion);
    forward.normalize();

    const sideways = new THREE.Vector3(1, 0, 0);
    sideways.applyQuaternion(controlObject.quaternion);
    sideways.normalize();

    sideways.multiplyScalar(velocity.x * deltaTime);
    forward.multiplyScalar(velocity.z * deltaTime);
    forward.add(sideways);

    if (playerBody) {
      forward.multiplyScalar(100);
      playerBody.getLinearVelocity(v0);
      v0.set(forward.x, v0.y, forward.z);
      playerBody.setLinearVelocity(v0);
      controlObject.position.copy(playerBody.root.position);
      controlObject.position.y -= page1 || prevPage1 ? 0.8 : page2 ? 0.6 : 0.8;
    }

    oldPosition.copy(controlObject.position);

    //Follow Cam
    //lock camera and controls target to position
    v0.copy(oldPosition);
    v0.y += 0.7;
    v0.sub(orbitControls.target);
    orbitControls.target.add(v0);
    camera.position.add(v0);

    // // Rotate camera behind the character
    // v0.copy(camera.position).sub(orbitControls.target);
    // v0.y = 0;
    // let dist = v0.length();
    // v0.set(0, 0, -1).applyQuaternion(controlObject.quaternion);
    // v0.setLength(dist);
    // v0.add(orbitControls.target);
    // v0.y = camera.position.y;
    // v0.sub(camera.position);
    // v0.multiplyScalar(0.02);
    // v0.y = 0;
    // camera.position.add(v0);
    // let sy = camera.position.y;
    // v0.copy(camera.position).sub(orbitControls.target);
    // v0.y = 0;
    // v0.setLength(dist);
    // camera.position.copy(v0).add(orbitControls.target);
    // camera.position.y = sy;
  }

  if (disable || disableHomeToScene1 || disableScene1ToContact) {
    orbitControls.enabled = false;
  } else {
    orbitControls.enabled = true;
  }
  if (
    characterControls &&
    !disable &&
    !disableHomeToScene1 &&
    !disableScene1ToContact
  ) {
    characterControls.update(mixerUpdateDelta, keysPressed);
  }

  if (textMixer) textMixer.update(mixerUpdateDelta);

  if (logoMixer) logoMixer.update(mixerUpdateDelta);

  if (homePageMixer) homePageMixer.update(mixerUpdateDelta);

  if (portalMixer) portalMixer.update(mixerUpdateDelta);

  if (portalMixerInteraction) portalMixerInteraction.update(mixerUpdateDelta);

  if (bookAnimMixer) bookAnimMixer.update(mixerUpdateDelta);

  if (flipPageOpenAnimMixer) flipPageOpenAnimMixer.update(mixerUpdateDelta);

  if (flipPageCloseAnimMixer) flipPageCloseAnimMixer.update(mixerUpdateDelta);

  if (mixer) mixer.update(deltaTime);

  if ((player && page1 && !page2) || prevPage1) {
    homePage();
    orbitControls.maxAzimuthAngle = (86 * Math.PI) / 180;
    orbitControls.minAzimuthAngle = (320 * Math.PI) / 180;
    orbitControls.maxPolarAngle = Math.PI / 2 - 0.05;
    orbitControls.minPolarAngle = Math.PI / 2.75;
    orbitControls.minDistance = 5;
    orbitControls.maxDistance = 14;
  } else if ((player && page2 && !page3) || prevPage2) {
    playerInteraction();
    if (disable) {
      orbitControlsDisable();
    } else {
      orbitControls.maxAzimuthAngle = (57 * Math.PI) / 180;
      orbitControls.minAzimuthAngle = (359 * Math.PI) / 180;
      orbitControls.maxPolarAngle = Math.PI / 2 - 0.05;
      orbitControls.minPolarAngle = Math.PI / 2.75;
      orbitControls.minDistance = 5;
      orbitControls.maxDistance = 14;
    }
  } else if (player && page3 && !prevPage2) {
    orbitControls.maxAzimuthAngle = (57 * Math.PI) / 180;
    orbitControls.minAzimuthAngle = (359 * Math.PI) / 180;
    orbitControls.maxPolarAngle = Math.PI / 2 - 0.05;
    orbitControls.minPolarAngle = Math.PI / 2.75;
    orbitControls.minDistance = 5;
    orbitControls.maxDistance = 14;
    setTimeout(() => {
      contactPageInteractions();
    }, 2000);
  }

  // Update orbit controls
  orbitControls.update();

  // Points for users
  if (sceneReady) {
    // Go through each point
    for (const point of pointsHome) {
      const screenPosition = point.position.clone();
      screenPosition.project(camera);

      raycaster.setFromCamera(screenPosition, camera);
      const intersects = raycaster.intersectObjects(scene1.children, true);

      if (
        characterControls &&
        !disable &&
        !disableHomeToScene1 &&
        !disableScene1ToContact &&
        !page2 &&
        !page3
      ) {
        if (intersects.length === 0) {
          setTimeout(() => point.element.classList.add("visible"), 2000);
        } else {
          const intersectionDistance = intersects[0].distance;
          const pointDistance = point.position.distanceTo(camera.position);

          if (intersectionDistance < pointDistance) {
            point.element.classList.remove("visible");
          } else {
            point.element.classList.add("visible");
          }
        }

        const translateX = screenPosition.x * window.innerWidth * 0.5;
        const translateY = -screenPosition.y * window.innerHeight * 0.5;
        point.element.style.transform = `translate(${translateX}px, ${translateY}px)`;
      } else {
        point.element.classList.remove("visible");
      }
    }
  }

  console.log("Number of Triangles :", renderer.info.render.triangles);

  //Update Physics
  let simStep = 1 / 60;
  let dt = deltaTime;
  let dramaTimeScale = 1;
  let fixed_ts = simStep * dramaTimeScale;
  let ts = dt * dramaTimeScale;
  let subSteps = 3;
  //sim.step()
  ammoPhysics.step(fixed_ts, subSteps, fixed_ts / subSteps);
  ammoPhysics.physicsDebugger.update();

  // Update Background Stars
  stars.rotation.y += 0.00005;

  // Swith Cameras
  if (renderer) {
    if (activeSecondCamera || page1) {
      renderer.render(scene, secondCamera);
    } else {
      renderer.render(scene, camera);
    }
  }

  // Update  Materials
  doorLightMaterial.uniforms.uTime.value = elapsedTime;
  firefliesMaterialUD.uniforms.uTime.value = elapsedTime;
  firefliesMaterialLR.uniforms.uTime.value = elapsedTime;

  if (!activeSecondCamera) {
    secondCamera.copy(camera);
  }
  requestAnimationFrame(animate);

  stats.end();
}
