import * as THREE from "three";
import { gsap } from "gsap";
import { scene, camera, orbitControls } from "../script";
import { forwardPopUpPage3 } from "../pageThree/ContactUsPage";
import {
  forwardPopUpPage1FromPage2,
  flipPageOpenAnimMixer,
} from "../pageOne/homePage";
import {
  addPage1Physics,
  addPage3Physics,
  removePage2Physics,
} from "../Utils/physicsAdd-Remove";
import {
  animations,
  portalAnimationsInteraction,
  flipPageClose,
  flipPageCloseAnimations,
  flipPageOpen,
  flipPageOpenAnimations,
  interactionScene,
  player,
  scene1,
  scenePortalInteraction,
} from "../Utils/loader";

let textMixer;
let portalMixerInteraction;
let playAnim;
let playAnimPortalInteraction;

let flipPageCloseAnimMixer = null;
let playAnimFlipPageClose = null;

let esc = false;
let prevPage1 = false;
let page2 = false;
let isTrue = false;

let disable = false;
let enter = false;
let activeSecondCamera = false;

const disableFunc = (val) => {
  disable = val;
};
const enterFunc = (val) => {
  enter = val;
};
const activeSecondCameraFunc = (val) => {
  activeSecondCamera = val;
};

const forwardPopUpPage2 = () => {
  prevPage1 = false;
  page2 = true;
  disableFunc(true);
  player.scale.set(2, 2, 2);
  textMixer = new THREE.AnimationMixer(interactionScene);
  portalMixerInteraction = new THREE.AnimationMixer(scenePortalInteraction);

  if (animations) {
    animations.forEach((clip) => {
      playAnim = textMixer.clipAction(clip);
      playAnim.reset();
      playAnim.clampWhenFinished = true;
      playAnim.setLoop(THREE.LoopOnce, 1);
      playAnim.timeScale = 1;
      playAnim.play();
    });
  }
  if (portalAnimationsInteraction) {
    portalAnimationsInteraction.forEach((clip) => {
      playAnimPortalInteraction = portalMixerInteraction.clipAction(clip);
      playAnimPortalInteraction.reset();
      playAnimPortalInteraction.clampWhenFinished = true;
      playAnimPortalInteraction.setLoop(THREE.LoopOnce, 1);
      playAnimPortalInteraction.timeScale = 1;
      playAnimPortalInteraction.play();
    });
  }
  scene.add(interactionScene);
  scene.add(scenePortalInteraction);

  setTimeout(() => {
    player.visible = true;
    disableFunc(false);

    isTrue = true;

    addPage2Btns();
    setTimeout(() => {
      isTrue = false;
    }, 400);
  }, 4000);

  setTimeout(() => {
    gsap.to(camera.position, {
      x: 9.3,
      y: 5.5,
      z: 10.6,
      duration: 1.5,
    });
  }, 2750);
};

const forwardPopUpPage2FromPage3 = () => {
  prevPage1 = false;
  page2 = true;
  disableFunc(true);
  player.scale.set(2, 2, 2);

  if (animations) {
    animations.forEach((clip) => {
      playAnim = textMixer.clipAction(clip);
      playAnim.reset();
      playAnim.clampWhenFinished = true;
      playAnim.setLoop(THREE.LoopOnce, 1);
      playAnim.timeScale = 2;
      playAnim.play();
    });
  }
  if (portalAnimationsInteraction) {
    portalAnimationsInteraction.forEach((clip) => {
      playAnimPortalInteraction = portalMixerInteraction.clipAction(clip);
      playAnimPortalInteraction.reset();
      playAnimPortalInteraction.clampWhenFinished = true;
      playAnimPortalInteraction.setLoop(THREE.LoopOnce, 1);
      playAnimPortalInteraction.timeScale = 2;
      playAnimPortalInteraction.play();
    });
  }
  scene.add(interactionScene);
  scene.add(scenePortalInteraction);

  setTimeout(() => {
    player.visible = true;
    disableFunc(false);
    addPage2Btns();
    scene.remove(flipPageClose);
    scene.add(flipPageOpen);
    gsap.to(camera.position, {
      x: 9.3,
      y: 5.5,
      z: 10.6,
      duration: 1.5,
    });
  }, 2000);
};

const reversePopUp = () => {
  player.visible = false;
  disableFunc(true);
  page2 = false;
  animations.forEach((clip) => {
    const action = textMixer.clipAction(clip);
    action.paused = false;
    action.setLoop(THREE.LoopOnce, 1);
    action.timeScale = -1;
    action.clampWhenFinished = true;
    action.play();
  });
  portalAnimationsInteraction.forEach((clip) => {
    const action = portalMixerInteraction.clipAction(clip);
    action.paused = false;
    action.setLoop(THREE.LoopOnce, 1);
    action.timeScale = -1;
    action.clampWhenFinished = true;
    action.play();
  });

  setTimeout(() => {
    scene.remove(interactionScene);
    scene.remove(scenePortalInteraction);
    disableFunc(false);
    forwardPopUpPage3();
    forwardPopUpFlipPageClose();
  }, 1600);
};

const reverseToPrevious1 = () => {
  player.visible = false;
  disableFunc(true);
  page2 = false;
  animations.forEach((clip) => {
    const action = textMixer.clipAction(clip);
    action.paused = false;
    action.setLoop(THREE.LoopOnce, 1);
    action.timeScale = -1;
    action.clampWhenFinished = true;
    action.play();
  });
  portalAnimationsInteraction.forEach((clip) => {
    const action = portalMixerInteraction.clipAction(clip);
    action.paused = false;
    action.setLoop(THREE.LoopOnce, 1);
    action.timeScale = -1;
    action.clampWhenFinished = true;
    action.play();
  });

  setTimeout(() => {
    scene.remove(interactionScene);
    scene.remove(scenePortalInteraction);
    disableFunc(false);
    prevPage1 = true;
    scene.add(scene1);
    forwardPopUpPage1FromPage2();
  }, 2700);

  setTimeout(() => {
    reversePopUpFlipPageOpen();
  }, 850);
};

const forwardPopUpFlipPageClose = () => {
  flipPageCloseAnimMixer = new THREE.AnimationMixer(flipPageClose);
  flipPageCloseAnimations.forEach((clip) => {
    if (clip.name === "Close") {
      playAnimFlipPageClose = flipPageCloseAnimMixer.clipAction(clip);
      playAnimFlipPageClose.reset();
      playAnimFlipPageClose.clampWhenFinished = true;
      playAnimFlipPageClose.setLoop(THREE.LoopOnce, 1);
      playAnimFlipPageClose.timeScale = 1;
      playAnimFlipPageClose.play();
    }
  });
  flipPageClose.scale.set(2, 2, 2);
  flipPageClose.rotation.set(0, 4.72, 0);
  flipPageClose.position.set(2, -1.55, 2);

  scene.add(flipPageClose);
};

const reversePopUpFlipPageOpen = () => {
  flipPageOpenAnimations.forEach((clip) => {
    if (clip.name === "open") {
      const action = flipPageOpenAnimMixer.clipAction(clip);
      action.paused = false;
      action.setLoop(THREE.LoopOnce, 1);
      action.timeScale = -1;
      action.clampWhenFinished = true;
      action.play();
    }
  });
};

const addPage2Btns = () => {
  servicesPrevBtn.classList.add("button-add");
  bitcoinTableBtn.classList.add("button-add");
  uixBtn.classList.add("button-add");
  arvrBtn.classList.add("button-add");
  webDesignBtn.classList.add("button-add");
  gameDevBtn.classList.add("button-add");
  mobileBtn.classList.add("button-add");
  nextBtn.classList.add("button-add");
  escBtn.classList.add("button-add");
};

const removePage2Btns = () => {
  servicesPrevBtn.classList.remove("button-add");
  bitcoinTableBtn.classList.remove("button-add");
  uixBtn.classList.remove("button-add");
  arvrBtn.classList.remove("button-add");
  webDesignBtn.classList.remove("button-add");
  gameDevBtn.classList.remove("button-add");
  mobileBtn.classList.remove("button-add");
  nextBtn.classList.remove("button-add");
  escBtn.classList.remove("button-add");
};

const orbitControlsDisable = () => {
  orbitControls.enabled = false;
  orbitControls.minDistance = 0;
  orbitControls.maxAzimuthAngle = [-2 * Math.PI, 2 * Math.PI];
  orbitControls.minAzimuthAngle = [-2 * Math.PI, 2 * Math.PI];
  orbitControls.maxPolarAngle = Math.PI;
  orbitControls.minPolarAngle = 0;
};

const bitcoinTableBtn = document.querySelector(".btc");
bitcoinTableBtn.addEventListener("click", () => {
  enter = true;
  orbitControlsDisable();
});

const uixBtn = document.querySelector(".uix");
uixBtn.addEventListener("click", () => {
  enter = true;
  orbitControlsDisable();
});

const arvrBtn = document.querySelector(".arvr");
arvrBtn.addEventListener("click", () => {
  enter = true;
  orbitControlsDisable();
});

const webDesignBtn = document.querySelector(".webDesign");
webDesignBtn.addEventListener("click", () => {
  enter = true;
  orbitControlsDisable();
});

const gameDevBtn = document.querySelector(".gameDev");
gameDevBtn.addEventListener("click", () => {
  enter = true;
  orbitControlsDisable();
});

const mobileBtn = document.querySelector(".mobile");
mobileBtn.addEventListener("click", () => {
  enter = true;
  orbitControlsDisable();
});

const escBtn = document.querySelector(".esc");
escBtn.addEventListener("mousedown", (e) => {
  esc = true;
  enter = false;
  orbitControls.enabled = true;
});
escBtn.addEventListener("mouseout", (e) => (esc = false));

const nextBtn = document.querySelector(".next");
nextBtn.addEventListener("click", () => {
  removePage2Btns();
  removePage2Physics();
  addPage3Physics();
  reversePopUp();
  enter = false;
});

const servicesPrevBtn = document.querySelector(".servicesPrev");
servicesPrevBtn.addEventListener("click", () => {
  servicesPrevBtn.classList.remove("visible");
  removePage2Btns();
  removePage2Physics();
  addPage1Physics();
  reverseToPrevious1();
  enter = false;
  prevPage1 = true;
});

document.addEventListener("keydown", (e) => onKeyDown(e));
document.addEventListener("keyup", (e) => onKeyUp(e));
const onKeyDown = (event) => {
  switch (event.keyCode) {
    case 27: // escape
      esc = true;
      break;
  }
};
const onKeyUp = (event) => {
  switch (event.keyCode) {
    case 27: // escape
      esc = false;
      break;
  }
};

export {
  esc,
  enter,
  page2,
  disable,
  textMixer,
  prevPage1,
  flipPageClose,
  activeSecondCamera,
  portalMixerInteraction,
  flipPageCloseAnimMixer,
  flipPageCloseAnimations,
  escBtn,
  uixBtn,
  arvrBtn,
  nextBtn,
  mobileBtn,
  gameDevBtn,
  webDesignBtn,
  servicesPrevBtn,
  bitcoinTableBtn,
  enterFunc,
  disableFunc,
  forwardPopUpPage2,
  orbitControlsDisable,
  activeSecondCameraFunc,
  forwardPopUpPage2FromPage3,
};
