import * as THREE from "three";

/** Object Meshes */
const planeGeometry = new THREE.PlaneGeometry(12, 7);
const planeMaterial = new THREE.MeshStandardMaterial({
  metalness: 0.3,
  roughness: 0.4,
  color: "yellow",
  side: THREE.DoubleSide,
});

const boxGeometry = new THREE.BoxGeometry(1, 1, 1);
const boxMaterial = new THREE.MeshStandardMaterial({
  metalness: 0.3,
  roughness: 0.4,
  color: "white",
});

const cylinderGeometry = new THREE.CylinderGeometry(2, 2, 2, 24);

/** PAGE 1 PHYSICS */
let leftWall = new THREE.Mesh(planeGeometry, planeMaterial);
leftWall.scale.set(0.38, 0.3, 0.01);
leftWall.position.set(-3.91, 1, -0.58);
leftWall.rotation.set(0, 1.578, 0);
leftWall.name = "LeftWall";

let page1BackWall = new THREE.Mesh(planeGeometry, planeMaterial);
page1BackWall.scale.set(0.9, 0.3, 0.01);
page1BackWall.position.set(0, 1, -2.84);
page1BackWall.rotation.set(0, 0.007, 0);
page1BackWall.name = "page1BackWall";

let twigWall = new THREE.Mesh(planeGeometry, planeMaterial);
twigWall.scale.set(0.13, 0.17, 0);
twigWall.position.set(-2.85, 0, -2.5);
twigWall.rotation.set(0, 0.7, 0);
twigWall.name = "twigWall";

let house = new THREE.Mesh(boxGeometry, boxMaterial);
house.scale.set(2.5, 1.15, 1.47);
house.position.set(0.05, 0, -0.8);
house.rotation.set(0, -1.578, 0);
house.name = "house";

let frontBlock1 = new THREE.Mesh(boxGeometry, boxMaterial);
frontBlock1.scale.set(0.6, 0.45, 0.08);
frontBlock1.position.set(0.69, 0.03, 0.66);
frontBlock1.rotation.set(0, -1.67, 0);
frontBlock1.name = "frontBlock1er";

let frontBlock2 = new THREE.Mesh(boxGeometry, boxMaterial);
frontBlock2.scale.set(0.08, 0.45, 0.6);
frontBlock2.position.set(-0.57, 0.03, 0.66);
frontBlock2.rotation.set(0, 0.12, 0);
frontBlock2.name = "frontBlock2er";

let frontBlock3 = new THREE.Mesh(boxGeometry, boxMaterial);
frontBlock3.scale.set(0.65, 0.45, 0.07);
frontBlock3.position.set(-0.24, 0.03, 1.14);
frontBlock3.rotation.set(0, 5.73, 0);
frontBlock3.name = "frontBlock3";

let frontBlock4 = new THREE.Mesh(boxGeometry, boxMaterial);
frontBlock4.scale.set(0.68, 0.45, 0.07);
frontBlock4.position.set(0.36, 0.03, 1.14);
frontBlock4.rotation.set(0, 0.57, 0);
frontBlock4.name = "frontBlock4";

let page1RightWall = new THREE.Mesh(planeGeometry, planeMaterial);
page1RightWall.scale.set(1.64, 0.3, -0.57);
page1RightWall.position.set(4.1, 0, -0.75);
page1RightWall.rotation.set(0, -1.578, 0);
page1RightWall.name = "page1RightWall";

let bush1 = new THREE.Mesh(boxGeometry, boxMaterial);
bush1.scale.set(0.77, 0.66, 1.64);
bush1.position.set(-3.76, -0.06, -1.58);
bush1.name = "bush1";

let bush2 = new THREE.Mesh(boxGeometry, boxMaterial);
bush2.scale.set(0.32, 0.66, 1.75);
bush2.position.set(-3.93, -0.06, 0.88);
bush2.name = "bush1";

let smallBush = new THREE.Mesh(boxGeometry, boxMaterial);
smallBush.scale.set(0.43, 0.66, 0.54);
smallBush.position.set(-3.59, -0.06, 0.26);
smallBush.name = "bush1";

let roundBush1 = new THREE.Mesh(boxGeometry, boxMaterial);
roundBush1.scale.set(0.23, 0.8, 1.3);
roundBush1.position.set(1.7, -0.2, -1.58);
roundBush1.name = "roundBush1";

let roundBush2 = new THREE.Mesh(boxGeometry, boxMaterial);
roundBush2.scale.set(0.23, 0.8, 1.3);
roundBush2.position.set(1.7, -0.2, 0.2);
roundBush2.name = "roundBush2";

let roundBush3 = new THREE.Mesh(boxGeometry, boxMaterial);
roundBush3.scale.set(1.8, 0.8, 0.3);
roundBush3.position.set(2.6, -0.2, 0.73);
roundBush3.name = "roundBush3";

let roundBush4 = new THREE.Mesh(boxGeometry, boxMaterial);
roundBush4.scale.set(1.55, 0.8, 0.33);
roundBush4.position.set(2.6, -0.2, -2.16);
roundBush4.name = "roundBush3";

let roundBush5 = new THREE.Mesh(boxGeometry, boxMaterial);
roundBush5.scale.set(0.25, 0.8, 3);
roundBush5.position.set(3.41, -0.2, -0.7);
roundBush5.name = "roundBush5";

let extremeRightTree = new THREE.Mesh(planeGeometry, planeMaterial);
extremeRightTree.scale.set(0.07, 0.15, 0);
extremeRightTree.position.set(3.68, 0.35, -2.8);
extremeRightTree.rotation.set(0, -0.8, 0);
extremeRightTree.name = "extremeRightTree";

let slate = new THREE.Mesh(boxGeometry, boxMaterial);
slate.scale.set(0.65, 1.18, 0.14);
slate.position.set(2.58, 0.35, -1.81);
slate.name = "slate";

/** PAGE 2 PHYSICS */
let backWall1 = new THREE.Mesh(boxGeometry, boxMaterial);
backWall1.scale.set(3.8, 2, 0.25);
backWall1.position.set(2.25, 1, -2.8);
backWall1.rotation.set(0, 0.007, 0);
backWall1.name = "BackWall1";

let backWall2 = new THREE.Mesh(boxGeometry, boxMaterial);
backWall2.scale.set(3.8, 2, 0.25);
backWall2.position.set(-2.25, 1, -2.8);
backWall2.rotation.set(0, 0.01, 0);
backWall2.name = "BackWall2";

let doorWall = new THREE.Mesh(planeGeometry, planeMaterial);
doorWall.scale.set(0.1, 0.3, 0.01);
doorWall.position.set(0.05, 1, -2.95);
backWall2.rotation.set(0, 0.01, 0);
doorWall.name = "DoorWall";

let rightWall = new THREE.Mesh(planeGeometry, planeMaterial);
rightWall.scale.set(0.38, 0.3, 0.01);
rightWall.position.set(4.2, 1, -0.58);
rightWall.rotation.set(0, -1.578, 0);
rightWall.name = "RightWall";

let consoleGaming = new THREE.Mesh(boxGeometry, boxMaterial);
consoleGaming.scale.set(2.35, 1.25, 0.5);
consoleGaming.position.set(2.6, 0.6, -2.61);
consoleGaming.name = "ConsoleGaming";

let rightController = new THREE.Mesh(boxGeometry, boxMaterial);
rightController.scale.set(0.38, 0.5, 0.24);
rightController.position.set(2.28, 0.2, -1.92);
rightController.rotation.set(0, -0.39, 0);
rightController.name = "RightController";

let leftController = new THREE.Mesh(boxGeometry, boxMaterial);
leftController.scale.set(0.38, 0.5, 0.26);
leftController.position.set(2.96, 0.2, -1.91);
leftController.rotation.set(0, 0.39, 0);
leftController.name = "RightController";

let sofa1 = new THREE.Mesh(boxGeometry, boxMaterial);
sofa1.scale.set(0.45, 0.53, 0.45);
sofa1.position.set(1.89, 0.25, -0.98);
sofa1.name = "Sofa1";

let sofa2 = new THREE.Mesh(boxGeometry, boxMaterial);
sofa2.scale.set(0.45, 0.53, 0.45);
sofa2.position.set(3.83, 0.25, -1);
sofa2.name = "Sofa2";

let mobileDev = new THREE.Mesh(boxGeometry, boxMaterial);
mobileDev.scale.set(0.5, 0.9, 0.45);
mobileDev.position.set(3.45, 0.45, 0.86);
mobileDev.name = "MobileDev";

let webDes1 = new THREE.Mesh(boxGeometry, boxMaterial);
webDes1.scale.set(0.53, 1, 1.54);
webDes1.position.set(-3.48, 0.48, -1.94);
webDes1.name = "WebDes1";

let webDes2 = new THREE.Mesh(boxGeometry, boxMaterial);
webDes2.scale.set(1.54, 1, 0.61);
webDes2.position.set(-2.45, 0.48, -2.45);
webDes2.name = "WebDes2";

let architectDesign = new THREE.Mesh(boxGeometry, boxMaterial);
architectDesign.scale.set(2.08, 0.4, 0.9);
architectDesign.position.set(-0.14, 0.19, -0.6);
architectDesign.name = "ArchitectDesign";

let ar_vr = new THREE.Mesh(cylinderGeometry, boxMaterial);
ar_vr.scale.set(0.16, 1.1, 0.37);
ar_vr.position.set(-3.51, 1.07, 1.1);
ar_vr.name = "AR/VR";

let ui_ux = new THREE.Mesh(boxGeometry, boxMaterial);
ui_ux.scale.set(0.57, 0.4, 0.65);
ui_ux.position.set(-0.26, 0.19, 1.3);
ui_ux.name = "UI/UX";

let pillar1 = new THREE.Mesh(boxGeometry, boxMaterial);
pillar1.scale.set(0.2, 2, 0.2);
pillar1.position.set(-1.67, 0.97, -0.51);
pillar1.name = "Pillar1";

let pillar2 = new THREE.Mesh(boxGeometry, boxMaterial);
pillar2.scale.set(0.2, 2, 0.2);
pillar2.position.set(1.45, 0.97, -0.54);
pillar2.name = "Pillar2";

/** PAGE 3 PHYSICS */
let linkedIn = new THREE.Mesh(boxGeometry, boxMaterial);
linkedIn.position.set(-2.43, 0.25, -2.33);
linkedIn.scale.set(1, 0.6, 0.6);
linkedIn.name = "LinkedIn";

let messages = new THREE.Mesh(boxGeometry, boxMaterial);
messages.position.set(-0.9, 0.25, -2.29);
messages.scale.set(1.14, 0.6, 0.8);
messages.name = "messages";

let telephone = new THREE.Mesh(boxGeometry, boxMaterial);
telephone.position.set(1.61, 0.25, -2.31);
telephone.scale.set(1.07, 0.6, 0.5);
telephone.name = "telephone";

let facebook = new THREE.Mesh(boxGeometry, boxMaterial);
facebook.position.set(2.87, 0.25, -2.33);
facebook.scale.set(1.07, 0.6, 0.5);
facebook.name = "facebook";

let cupboard = new THREE.Mesh(boxGeometry, boxMaterial);
cupboard.position.set(-3.7, 0.64, -1.81);
cupboard.rotation.set(0, 0.34, 0);
cupboard.scale.set(0.4, 1.35, 0.32);
cupboard.name = "cupboard";

let frontBoxes = new THREE.Mesh(boxGeometry, boxMaterial);
frontBoxes.position.set(-0.25, 0.19, 1.4);
frontBoxes.scale.set(8.89, 1.64, 0.9);
frontBoxes.name = "frontBoxes";

let rightBoxes = new THREE.Mesh(boxGeometry, boxMaterial);
rightBoxes.position.set(3.7, 0.4, 0.02);
rightBoxes.scale.set(0.63, 1, 2.45);
rightBoxes.name = "rightBoxes";

let page3RightWall = new THREE.Mesh(planeGeometry, planeMaterial);
page3RightWall.position.set(3.85, 1, -0.6);
page3RightWall.rotation.set(0, 1.57, 0);
page3RightWall.scale.set(0.4, 0.35, 0);
page3RightWall.name = "page3RightWall";

let page3LeftWall = new THREE.Mesh(planeGeometry, planeMaterial);
page3LeftWall.position.set(-4.4, 1, -0.6);
page3LeftWall.rotation.set(0, 1.57, 0);
page3LeftWall.scale.set(0.4, 0.35, 0);
page3LeftWall.name = "page3LeftWall";

let page3BackWall = new THREE.Mesh(planeGeometry, planeMaterial);
page3BackWall.scale.set(0.9, 1.3, 0.29);
page3BackWall.position.set(0.41, 0.17, -3.2);
page3BackWall.rotation.set(32.25, 0, 0);
page3BackWall.name = "page3BackWall";

export {
  sofa1,
  sofa2,
  slate,
  bush1,
  bush2,
  house,
  ar_vr,
  ui_ux,
  pillar1,
  pillar2,
  webDes1,
  webDes2,
  facebook,
  cupboard,
  linkedIn,
  messages,
  twigWall,
  doorWall,
  leftWall,
  backWall1,
  backWall2,
  rightWall,
  telephone,
  smallBush,
  mobileDev,
  frontBoxes,
  rightBoxes,
  roundBush1,
  roundBush2,
  roundBush3,
  roundBush4,
  roundBush5,
  frontBlock1,
  frontBlock2,
  frontBlock3,
  frontBlock4,
  consoleGaming,
  page1BackWall,
  page3LeftWall,
  page3BackWall,
  page3RightWall,
  page1RightWall,
  leftController,
  rightController,
  architectDesign,
  extremeRightTree,
};
