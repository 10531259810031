import * as THREE from "three";
import {
  flipPageCloseAnimMixer,
  flipPageCloseAnimations,
  forwardPopUpPage2FromPage3,
} from "../pageTwo/interactions";
import { scene, camera } from "../script";
import { gsap } from "gsap";
import {
  addPage2Physics,
  removePage3Physics,
} from "../Utils/physicsAdd-Remove";
import { contactAnimations, player, scene3 } from "../Utils/loader";

let logoMixer;
let playAnim;
let linkedIn;
let outLook;
let facebook;
let whatsapp;

let disableScene1ToContact = false;
let page3 = false;

const facebookBtn = document.querySelector(".icon__facebook");
const whatsappBtn = document.querySelector(".icon__whatsapp");
const outlookBtn = document.querySelector(".icon__outlook");
const linkedinBtn = document.querySelector(".icon__linkedin");
const iconDiv = document.querySelector(".icon");

const contactPrevBtn = document.querySelector(".contactPrev");
contactPrevBtn.disabled = true;
contactPrevBtn.addEventListener("click", () => {
  contactPrevBtn.classList.remove("visible");
  removePage3Btns();
  reversePopUpPage3();
  removePage3Physics();
  addPage2Physics();
});

// Prev page logic
let prevPage2 = false;

const addPage3Btns = () => {
  iconDiv.classList.add("button-add");
  contactPrevBtn.classList.add("button-add");
  facebookBtn.classList.add("button-add");
  whatsappBtn.classList.add("button-add");
  outlookBtn.classList.add("button-add");
  linkedinBtn.classList.add("button-add");
};

const removePage3Btns = () => {
  iconDiv.classList.remove("button-add");
  contactPrevBtn.classList.remove("button-add");
  facebookBtn.classList.remove("button-add");
  whatsappBtn.classList.remove("button-add");
  outlookBtn.classList.remove("button-add");
  linkedinBtn.classList.remove("button-add");
  contactPrevBtn.disabled = true;
};

const forwardPopUpPage3 = () => {
  prevPage2 = false;
  page3 = true;
  disableScene1ToContact = true;
  logoMixer = new THREE.AnimationMixer(scene3);
  scene3.getObjectByName("Linkdin").visible = false;
  scene3.getObjectByName("massenger").visible = false;
  scene3.getObjectByName("whatsapp002").visible = false;
  scene3.getObjectByName("facebook").visible = false;

  if (contactAnimations) {
    contactAnimations.forEach((clip) => {
      if (
        clip.name == "Linkdin" ||
        clip.name == "whatsapp002" ||
        clip.name == "massenger" ||
        clip.name == "facebook"
      ) {
        return;
      } else {
        playAnim = logoMixer.clipAction(clip);
        playAnim.reset();
        playAnim.clampWhenFinished = true;
        playAnim.setLoop(THREE.LoopOnce, 1);
        playAnim.timeScale = 1;
        playAnim.play();
      }
    });
  }
  scene.add(scene3);

  setTimeout(() => {
    player.visible = true;
    disableScene1ToContact = false;
    addPage3Btns();
  }, 3500);

  setTimeout(() => {
    gsap.to(camera.position, {
      x: 9.3,
      y: 5.5,
      z: 10.6,
      duration: 1.5,
    });
  }, 2500);
};

const reversePopUpPage3 = () => {
  player.visible = false;
  disableScene1ToContact = true;
  page3 = false;
  scene3.getObjectByName("whatsapp002").visible = false;
  scene3.getObjectByName("Linkdin").visible = false;
  scene3.getObjectByName("massenger").visible = false;
  scene3.getObjectByName("facebook").visible = false;

  contactAnimations.forEach((clip) => {
    const action = logoMixer.clipAction(clip);
    action.paused = false;
    action.setLoop(THREE.LoopOnce, 1);
    action.timeScale = -1 / 3;
    action.clampWhenFinished = true;
    action.play();
  });

  setTimeout(() => {
    scene.remove(scene3);
    prevPage2 = true;
    disableScene1ToContact = false;
    forwardPopUpPage2FromPage3();
  }, 2750);

  setTimeout(() => {
    reversePopUpFlipPageClose();
  }, 1000);
};

const reversePopUpFlipPageClose = () => {
  flipPageCloseAnimations.forEach((clip) => {
    if (clip.name === "Close") {
      const action = flipPageCloseAnimMixer.clipAction(clip);
      action.paused = false;
      action.setLoop(THREE.LoopOnce, 1);
      action.timeScale = -1.2;
      action.clampWhenFinished = true;
      action.play();
    }
  });
};

const contactPageInteractions = () => {
  if (
    player.position.x <= -1.8 &&
    player.position.x >= -3 &&
    player.position.z <= -1.1 &&
    player.position.z >= -2
  ) {
    if (contactAnimations) {
      linkedinBtn.classList.remove("invisible");
      linkedinBtn.classList.add("visible");
      scene3.getObjectByName("Linkdin").visible = true; // linkedin
      linkedIn = logoMixer.clipAction(contactAnimations[13]);
      linkedIn.paused = false;
      linkedIn.setLoop(THREE.LoopOnce, 1);
      linkedIn.clampWhenFinished = true;
      linkedIn.timeScale = 5;
      linkedIn.play();
    }
  } else if (
    player.position.x <= -0.38 &&
    player.position.x >= -1.44 &&
    player.position.z <= -1.34 &&
    player.position.z >= -1.75
  ) {
    if (contactAnimations) {
      outlookBtn.classList.remove("invisible");
      outlookBtn.classList.add("visible");
      scene3.getObjectByName("massenger").visible = true; // mail
      outLook = logoMixer.clipAction(contactAnimations[16]);
      outLook.paused = false;
      outLook.setLoop(THREE.LoopOnce, 1);
      outLook.clampWhenFinished = true;
      outLook.timeScale = 5;
      outLook.play();
    }
  } else if (
    player.position.x >= 1.05 &&
    player.position.x <= 2.05 &&
    player.position.z <= -1.38 &&
    player.position.z >= -1.95
  ) {
    if (contactAnimations) {
      whatsappBtn.classList.remove("invisible");
      whatsappBtn.classList.add("visible");
      scene3.getObjectByName("whatsapp002").visible = true; // whatsapp
      whatsapp = logoMixer.clipAction(contactAnimations[15]);
      whatsapp.paused = false;
      whatsapp.setLoop(THREE.LoopOnce, 1);
      whatsapp.clampWhenFinished = true;
      whatsapp.timeScale = 5;
      whatsapp.play();
    }
  } else if (
    player.position.x >= 2.35 &&
    player.position.x <= 3.35 &&
    player.position.z <= -1.38 &&
    player.position.z >= -1.95
  ) {
    if (contactAnimations) {
      facebookBtn.classList.remove("invisible");
      facebookBtn.classList.add("visible");
      scene3.getObjectByName("facebook").visible = true; // facebook
      facebook = logoMixer.clipAction(contactAnimations[14]);
      facebook.paused = false;
      facebook.setLoop(THREE.LoopOnce, 1);
      facebook.clampWhenFinished = true;
      facebook.timeScale = 5;
      facebook.play();
    }
  } else if (
    player.position.x >= -0.03 &&
    player.position.x <= 0.75 &&
    player.position.z >= -2.01 &&
    player.position.z <= -1.45
  ) {
    contactPrevBtn.classList.remove("invisible");
    contactPrevBtn.classList.add("visible");
    contactPrevBtn.disabled = page3 ? false : true;

    if (
      contactPrevBtn.classList.contains("visible") &&
      contactPrevBtn.classList.contains("button-add")
    ) {
      document.addEventListener("keypress", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
          contactPrevBtn.click();
          contactPrevBtn.disabled = true;
        }
      });
    }
  } else {
    if (contactAnimations) {
      linkedIn = logoMixer.clipAction(contactAnimations[13]);
      linkedIn.paused = false;
      linkedIn.setLoop(THREE.LoopOnce, 1);
      linkedIn.clampWhenFinished = true;
      linkedIn.timeScale = -1;
      linkedIn.play();

      outLook = logoMixer.clipAction(contactAnimations[16]);
      outLook.paused = false;
      outLook.setLoop(THREE.LoopOnce, 1);
      outLook.clampWhenFinished = true;
      outLook.timeScale = -1;
      outLook.play();

      whatsapp = logoMixer.clipAction(contactAnimations[15]);
      whatsapp.paused = false;
      whatsapp.setLoop(THREE.LoopOnce, 1);
      whatsapp.clampWhenFinished = true;
      whatsapp.timeScale = -1;
      whatsapp.play();

      facebook = logoMixer.clipAction(contactAnimations[14]);
      facebook.paused = false;
      facebook.setLoop(THREE.LoopOnce, 1);
      facebook.clampWhenFinished = true;
      facebook.timeScale = -1;
      facebook.play();

      scene3.getObjectByName("Linkdin").visible = false;
      scene3.getObjectByName("massenger").visible = false;
      scene3.getObjectByName("whatsapp002").visible = false;
      scene3.getObjectByName("facebook").visible = false;
    }

    facebookBtn.classList.add("invisible");
    whatsappBtn.classList.add("invisible");
    outlookBtn.classList.add("invisible");
    linkedinBtn.classList.add("invisible");
    contactPrevBtn.classList.add("invisible");
    contactPrevBtn.disabled = true;
  }
};

export {
  page3,
  scene3,
  playAnim,
  logoMixer,
  prevPage2,
  contactAnimations,
  disableScene1ToContact,
  contactPageInteractions,
  forwardPopUpPage3,
};
