export const W = "keyw";
export const A = "keya";
export const S = "keys";
export const D = "keyd";
export const SHIFTLEFT = "shiftleft";
export const SHIFTRIGHT = "shiftright";
export const LEFT = "arrowleft";
export const RIGHT = "arrowright";
export const UP = "arrowup";
export const DOWN = "arrowdown";
export const DIRECTIONS = [W, A, S, D, LEFT, RIGHT, UP, DOWN];
export const SHIFTKeyPRESSED = [SHIFTLEFT, SHIFTRIGHT];
export class KeyDisplay {
  constructor() {
    //     this.map = new Map();
    //     const w = document.createElement("div");
    //     const a = document.createElement("div");
    //     const s = document.createElement("div");
    //     const d = document.createElement("div");
    //     const shift = document.createElement("div");
    //     const left = document.createElement("div");
    //     this.map.set(W, w);
    //     this.map.set(A, a);
    //     this.map.set(S, s);
    //     this.map.set(D, d);
    //     this.map.set(SHIFT, shift);
    //     this.map.set(LEFT, left);
    //     this.map.forEach((v, k) => {
    //         v.style.color = 'blue';
    //         v.style.fontSize = '50px';
    //         v.style.fontWeight = '800';
    //         v.style.position = 'absolute';
    //         v.textContent = k;
    //     });
    //     this.updatePosition();
    //     this.map.forEach((v, _) => {
    //         document.body.append(v);
    //     });
    // }
    // updatePosition() {
    //     this.map.get(W).style.top = `${window.innerHeight - 150}px`;
    //     this.map.get(A).style.top = `${window.innerHeight - 100}px`;
    //     this.map.get(S).style.top = `${window.innerHeight - 100}px`;
    //     this.map.get(D).style.top = `${window.innerHeight - 100}px`;
    //     this.map.get(SHIFT).style.top = `${window.innerHeight - 100}px`;
    //     this.map.get(LEFT).style.top = `${window.innerHeight - 100}px`;
    //     this.map.get(W).style.left = `${300}px`;
    //     this.map.get(A).style.left = `${200}px`;
    //     this.map.get(S).style.left = `${300}px`;
    //     this.map.get(D).style.left = `${400}px`;
    //     this.map.get(LEFT).style.left = `${500}px`;
    //     this.map.get(SHIFT).style.left = `${50}px`;
    // }
    // down(key) {
    //     if (this.map.get(key.toLowerCase())) {
    //         this.map.get(key.toLowerCase()).style.color = 'red';
    //     }
    // }
    // up(key) {
    //     if (this.map.get(key.toLowerCase())) {
    //         this.map.get(key.toLowerCase()).style.color = 'blue';
    //     }
  }
}
