import * as THREE from "three";

export default class PhysicsCollisionUtils {
  constructor(ammoPhysics, forceVisible = false) {
    let makeMeshCollider = (this.makeMeshCollider = (
      mesh,
      _forceVisible = forceVisible
    ) => {
      let shape = ammoPhysics.shapes.createShape(mesh, {
        type: "mesh",
      });
      let body = ammoPhysics.createObject({
        root: mesh,
        shape,
        collisionType: ammoPhysics.ctTerrain,
        mass: 0,
        restitution: 0.75,
        friction: 0.9,
      });
      mesh.visible = _forceVisible;
      let pos = mesh.localToWorld(new THREE.Vector3());
      body.setPosition(pos);
    });

    let makeBoxCollider = (this.makeBoxCollider = (
      mesh,
      _forceVisible = forceVisible
    ) => {
      if (!mesh) return;

      let shape = ammoPhysics.shapes.createShape(mesh, {
        type: "box",
      });
      let body = ammoPhysics.createObject({
        root: mesh,
        shape,
        collisionType: ammoPhysics.ctTerrain,
        mass: 0,
        restitution: 0.3,
        friction: 0.9,
      });

      mesh.visible = _forceVisible;
      let pos = mesh.localToWorld(new THREE.Vector3());
      body.setPosition(pos);
    });

    let removeBoxCollider = (this.removeBoxCollider = (mesh) => {
      if (!mesh) return;

      if (mesh) {
        ammoPhysics.removeDebris(mesh);
      }
    });

    // this.addPrefabMeshCollider = (name, _forceVisible = forceVisible) => {
    //   ammoPhysics.scene.attach(ammoPhysics.prefabs[name]);
    //   makeMeshCollider(ammoPhysics.prefabs[name]);
    //   ammoPhysics.prefabs[name].visible = _forceVisible;
    //   if (name === "collision_hoop2" || name === "collision_hoop1") {
    //     //TODO - Need to REMOVE this part after it will be done in glb file
    //     ammoPhysics.prefabs[name].position.y += -0.18;
    //     const geometry = new THREE.TorusGeometry(0.25, 0.03, 8, 21);
    //     const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    //     const torus = new THREE.Mesh(geometry, material);
    //     ammoPhysics.scene.attach(torus);
    //     torus.position.copy(ammoPhysics.prefabs[name].position);
    //     torus.rotation.x = Math.PI / 2;
    //     makeMeshCollider(torus);
    //     // torus.visible = true
    //   }
    // };

    // this.addPrefabBoxCollider = (name, _forceVisible = forceVisible) => {
    //   ammoPhysics.scene.attach(ammoPhysics.prefabs[name]);
    //   makeBoxCollider(ammoPhysics.prefabs[name]);
    //   ammoPhysics.prefabs[name].visible = _forceVisible;
    // };
  }
}
