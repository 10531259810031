let Ammo;
let tbq40
let tbv30
let tbv31
let transformAux1;
let tv30
let tv31

class RigidBody {
	set body(v){
		debugger
	}
	get body(){
		debugger
	}
	constructor() {
		this.collided = false
	}
	get fuse() {
		return this._fuse
	}
	set fuse(v) {
		this._fuse = v;
		this.onFuseChange && this.onFuseChange()

		if (this.nextFused === undefined) {
			this.nextFused = app.physics.fusedList.nextFused;
			app.physics.fusedList.nextFused = this;
		}
	}
	setSleepingThresholds(linearThreshold,angularThreshold){
		return this._body.setSleepingThresholds(linearThreshold,angularThreshold)
	}
	setPosition(pv3) {
		let ms = this._body.getMotionState();
		ms.getWorldTransform(transformAux1)
		transformAux1.getOrigin().setValue(pv3.x, pv3.y, pv3.z)
		this._body.setWorldTransform(transformAux1)
		ms.setWorldTransform(transformAux1)
	}
	setRotation(q4) {
		var transform = this._body.getCenterOfMassTransform();
		let ms = this._body.getMotionState()
		ms.getWorldTransform(transformAux1)
		tbq40.setValue(q4.x, q4.y, q4.z, q4.w)
		transformAux1.setRotation(tbq40)
		this._body.setWorldTransform(transformAux1);
		ms.setWorldTransform(transformAux1)
		//this._body.setCenterOfMassTransform(transform);
	}
	getLinearVelocity(v3) {
		let lv = this._body.getLinearVelocity();
		v3.set(lv.x(), lv.y(), lv.z())
		return v3;
	}
	setCollisionFlags( flagsInt ) {
        return this._body.setCollisionFlags(flagsInt);
	}
	getCollisionFlags(){
		return this._body.getCollisionFlags();
	}
	
	activate(activationFlags = 0){
		this._body.activate( activationFlags )
	}
	
	setMass(mass) {
		this.mass = mass;
		this._body.setMassProps(mass,mass?this.localInertia:(tbv30.setValue(0,0,0)||tbv30))
	}
	clearForces(){
        this._body.clearForces();
	}
	setAngularFactor(pv3) {
		if(!pv3.isVector3)debugger;
			this._body.getAngularFactor().setValue(pv3.x, pv3.y, pv3.z)
	}
	setAngularVelocity(pv3) {
		if(!pv3.isVector3)debugger;
	//	this._body.setAngularVelocity(new Ammo.btVector3(pv3.x, pv3.y, pv3.z))
		this._body.getAngularVelocity().setValue(pv3.x, pv3.y, pv3.z)
	}
	setLinearVelocity(pv3) {
		if(!pv3.isVector3)debugger;
		this._body.getLinearVelocity().setValue(pv3.x, pv3.y, pv3.z)
	}
	activate(){
		this._body.activate();
	}
	applyBodyForce(body, impulse) {
		tbv30.setValue(impulse.x, impulse.y, impulse.z);
		this._body.applyCentralForce(tbv30);
	}

	applyRelativeForceAtPoint(body, impulse, point) {
		this._body.motionProxy.localToWorld(tv30.copy(impulse));
		this._body.motionProxy.worldToLocal(tv31.copy(point))
		this.applyBodyForceAtPoint(body, tv30, tv31);
	}

	applyBodyForceAtPoint(body, impulse, point) {
		tbv30.setValue(impulse.x, impulse.y, impulse.z);
		tbv31.setValue(point.x, point.y, point.z);
		this._body.applyForce(tbv30, tbv31);
	}

	applyCentralForce(v3) {
		this._body.applyCentralForce(tbv30.setValue(v3.x, v3.y, v3.z))
	}
	applyCentralImpulse(v3) {
		this._body.applyCentralImpulse(tbv30.setValue(v3.x, v3.y, v3.z));
	}
	applyForce(v3force, at) {
		this._body.applyForce(tbv30.setValue(v3force.x, v3force.y, v3force.z), tbv31.setValue(at.x, at.y, at.z))
	}
	applyImpulse(v3impulse, at) {
		this._body.applyImpulse(tbv30.setValue(v3impulse.x, v3impulse.y, v3impulse.z), tbv31.setValue(at.x, at.y, at.z));
	}

}
RigidBody.init = (app) => {
	let THREE = app.THREE;
	Ammo = app.Ammo;
	tv30 = new THREE.Vector3()
	tv31 = new THREE.Vector3()
	tbq40 = new Ammo.btQuaternion();
	tbv30 = new Ammo.btVector3();
	tbv31 = new Ammo.btVector3();
	transformAux1 = new Ammo.btTransform();
}
export default RigidBody
