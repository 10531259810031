import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Physics
import Physics from "./physics/Physics";
import PhysicsCollisionUtils from "./PhysicsCollisionUtils";
import nipplejs from "nipplejs";
import { animate } from "./Utils/animate";
import { addPage1Physics } from "./Utils/physicsAdd-Remove";

import Stats from "stats.js";

import "./styles.css";
import "./button.scss";
import { characterControls } from "./Utils/loader";

import firefliesVertexShaderUD from "./shaders/fireflies/vertexUD.glsl";
import firefliesVertexShaderLR from "./shaders/fireflies/vertexLR.glsl";
import firefliesFragmentShader from "./shaders/fireflies/fragment.glsl";
import doorVertexShader from "./shaders/door/vertex.glsl";
import doorFragmentShader from "./shaders/door/fragment.glsl";

let movementControllerDisplay;

if (window.innerWidth > 981) {
  movementControllerDisplay = document.getElementById(
    "initial-arrow-container"
  );
}

/** Base **/
document.onkeydown = (t) => {
  if (t.which == 9) {
    // Disabling TAB key
    return false;
  } else if (
    t.which == 87 ||
    t.which == 65 ||
    t.which == 83 ||
    t.which == 68 ||
    t.which == 37 ||
    t.which == 38 ||
    t.which == 39 ||
    t.which == 40
  ) {
    setTimeout(() => {
      movementControllerDisplay.classList.add("fade-out");
      movementControllerDisplay.classList.remove("fade-in");
      setTimeout(() => {
        movementControllerDisplay.classList.add("display-none");
      }, 3000);
    }, 2000);
  }
};

/** Sizes **/
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

/** SCENE **/
const scene = new THREE.Scene();

/** JoyStick for mobile **/
let joyManager;
window.addEventListener("load", () => {
  if (sizes.width <= 980) {
    const options = {
      zone: document.getElementById("joystick"),
      position: { bottom: "95px", left: "95px" },
      mode: "static",
      size: 100,
      multitouch: true,
      maxNumberOfNipples: 2,
      shape: "circle",
      dynamicPage: true,
    };
    joyManager = nipplejs.create(options);
  }
});

/** CAMERA **/
const camera = new THREE.PerspectiveCamera(
  25,
  sizes.width / sizes.height,
  0.1,
  1000
);
camera.position.set(9.3, 5.5, 10.6);

const secondCamera = new THREE.PerspectiveCamera(
  25,
  sizes.width / sizes.height,
  0.1,
  1000
);

scene.add(camera);
scene.add(secondCamera);

/** Stats **/
const stats = new Stats();
stats.showPanel(0);
document.body.appendChild(stats.dom);

/** Door Light **/
const doorLightMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    uColorStart: { value: new THREE.Color("#FE542A") },
    uColorEnd: { value: new THREE.Color("#FFF080") },
  },
  vertexShader: doorVertexShader,
  fragmentShader: doorFragmentShader,
});

/** Fireflies **/
const firefliesGeometry = new THREE.BufferGeometry();
const firefliesCount = 120;
const positionArray = new Float32Array(firefliesCount * 3);
const scaleArray = new Float32Array(firefliesCount);

for (let i = 0; i < firefliesCount; i++) {
  positionArray[i * 3] = (Math.random() - 0.5) * 8;
  positionArray[i * 3 + 1] = Math.random() * 0.5;
  positionArray[i * 3 + 2] = (Math.random() - 0.5) * 3;

  scaleArray[i] = Math.random();
}

firefliesGeometry.setAttribute(
  "position",
  new THREE.BufferAttribute(positionArray, 3)
);
firefliesGeometry.setAttribute(
  "aScale",
  new THREE.BufferAttribute(scaleArray, 1)
);

// Material
const firefliesMaterialUD = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    uPixelRatio: { value: Math.min(window.devicePixelRatio * 0.85, 2) },
    uSize: { value: 500 },
    uColor: { value: new THREE.Color("#FFCB3D") },
  },
  vertexShader: firefliesVertexShaderUD,
  fragmentShader: firefliesFragmentShader,
  transparent: true,
  blending: THREE.AdditiveBlending,
  depthWrite: false,
});
const firefliesMaterialLR = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    uPixelRatio: { value: Math.min(window.devicePixelRatio * 0.85, 2) },
    uSize: { value: 250 },
    uColor: { value: new THREE.Color("#FFCB3D") },
  },
  vertexShader: firefliesVertexShaderLR,
  fragmentShader: firefliesFragmentShader,
  transparent: true,
  blending: THREE.AdditiveBlending,
  depthWrite: false,
});

// Points
const firefliesUD = new THREE.Points(firefliesGeometry, firefliesMaterialUD);
const firefliesLR = new THREE.Points(firefliesGeometry, firefliesMaterialLR);

/** Background Stars **/
let count = 8000;
const starGeometry = new THREE.BufferGeometry();
const positions = new Float32Array(count * 3);

for (let i = 0; i < count; i++) {
  const i3 = i * 3;

  positions[i3] = (Math.random() - 0.5) * 20;
  positions[i3 + 1] = (Math.random() - 0.5) * 20;
  positions[i3 + 2] = (Math.random() - 0.5) * 20;
}
starGeometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

let sprite = new THREE.TextureLoader().load("/resources/Images/stars.png");
let startMaterial = new THREE.PointsMaterial({
  size: 0.025,
  map: sprite,
  sizeAttenuation: true,
  depthWrite: false,
  blending: THREE.AdditiveBlending,
});

let stars = new THREE.Points(starGeometry, startMaterial);
scene.add(stars);

// RENDERER
// const renderer = new THREE.WebGLRenderer({ antialias: true });
const renderer = new THREE.WebGLRenderer({ antialias: false });
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(Math.min(window.devicePixelRatio * 0.85, 2));
renderer.outputEncoding = THREE.sRGBEncoding;
// renderer.outputColorSpace = THREE.SRGBColorSpace;
renderer.toneMapping = THREE.LinearToneMapping;
renderer.toneMappingExposure = 1.1;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;

// CONTROLS
const orbitControls = new OrbitControls(camera, renderer.domElement);
orbitControls.enableDamping = false;
orbitControls.enablePan = false;
orbitControls.rotateSpeed = 0.1;
orbitControls.enabled = true;

/**  Points of interest **/
const raycaster = new THREE.Raycaster();
const pointsHome = [
  {
    position: new THREE.Vector3(0.8, 0.95, -0.8),
    element: document.querySelector(".point-enter"),
  },
];
const pointsInteraction = [
  {
    position: new THREE.Vector3(0.8, 0.95, -0.8),
    element: document.querySelector(".point-exit"),
  },
];

/** Overlay **/
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
const overlayMaterial = new THREE.ShaderMaterial({
  transparent: true,
  uniforms: { uAlpha: { value: 1 } },
  vertexShader: `
      void main()
      {
        gl_Position = vec4(position, 1.0);
      }
  `,
  fragmentShader: `
      uniform float uAlpha;

      void main()
      {
        gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
      }
  `,
});
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

/** Physics **/
await Physics.init();
let softBody = false;
let ammoPhysics = new Physics({ THREE, scene, renderer, softBody });

let { makeMeshCollider, makeBoxCollider, removeBoxCollider } =
  new PhysicsCollisionUtils(ammoPhysics, true);

/** Object Meshes */
const planeGeometry = new THREE.PlaneGeometry(12, 7);
const planeMaterial = new THREE.MeshStandardMaterial({
  metalness: 0.3,
  roughness: 0.4,
  color: "yellow",
  side: THREE.DoubleSide,
});

/** GENERAL PHYSICS */
let floor = new THREE.Mesh(planeGeometry, planeMaterial);
floor.scale.set(1, 2, 1);
floor.position.set(0, 0, -3);
floor.quaternion.setFromAxisAngle(new THREE.Vector3(-1, 0, 0), Math.PI * 0.5);
makeMeshCollider(floor, true);

let frontWall = new THREE.Mesh(planeGeometry, planeMaterial);
frontWall.scale.set(0.66, 0.3, 0.01);
frontWall.position.set(0.22, 1, 1.7);
frontWall.rotation.set(0, 3.15, 0);
frontWall.name = "FrontWall";
makeMeshCollider(frontWall, true);

/** Character's Physics Body **/
let makeObject = (params = {}) => {
  let {
    x = 1.4,
    y = 1,
    z = 0.3,
    sx = 0.35,
    sy = 0.35,
    sz = 0.35,
    rx = 0,
    ry = 0,
    rz = 0,
    mass = 0,
    fuse = 0,
    restitution = 0.01,
    friction = 0.9,
    type = "box",
    radius,
    height,
    axis,
    kinematic,
  } = params;

  let mesh = new THREE.Object3D();
  mesh.scale.set(sx, sy, sz);
  mesh.position.set(x, y, z);
  mesh.rotation.set(rx, ry, rz);
  let shape = ammoPhysics.shapes.createShape(mesh, {
    type,
    sx,
    sy,
    sz,
    radius,
    height,
    axis,
  });
  let body = ammoPhysics.createObject({
    root: mesh,
    shape,
    collisionType: ammoPhysics.ctTerrain,
    mass,
    restitution,
    friction,
    kinematic,
  });
  return body;
};

let params = {};
const playerBody = makeObject(
  Object.assign(
    {
      y: 0.01,
      sy: 1,
      mass: 65.7, //in kilos --> 144.84 lbs
      type: "capsule",
      radius: 0.15,
      height: 0.9,
      axis: "y",
      friction: 0,
      restition: 0,
    },
    params
  )
);

playerBody.setSleepingThresholds(0, 0);
let v0 = new THREE.Vector3();
v0.set(0, 0, 0);
//Pin inertia tensor
playerBody.setAngularFactor(v0);
scene.traverse((e) => e.isMesh && (e.castShadow = e.receiveShadow = true));

// CONTROL KEYS
const keysPressed = {};
document.addEventListener(
  "keydown",
  (event) => {
    if (event.shiftKey && characterControls) {
      characterControls.switchRunToggleTrue();
      keysPressed[event.code.toLowerCase()] = true;
    } else {
      keysPressed[event.code.toLowerCase()] = true;
    }
  },
  false
);
document.addEventListener(
  "keyup",
  (event) => {
    if (characterControls) characterControls.switchRunToggleFalse();
    keysPressed[event.code.toLowerCase()] = false;
  },
  false
);

// RESIZE HANDLER
window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio * 0.85, 2));

  // Update fireflies
  firefliesMaterialUD.uniforms.uPixelRatio.value = Math.min(
    window.devicePixelRatio * 0.85,
    2
  );
  firefliesMaterialLR.uniforms.uPixelRatio.value = Math.min(
    window.devicePixelRatio * 0.85,
    2
  );
});

addPage1Physics();
document.body.appendChild(renderer.domElement);
animate();

export {
  v0,
  stars,
  joyManager,
  ammoPhysics,
  movementControllerDisplay,
  stats,
  scene,
  camera,
  pointsHome,
  renderer,
  raycaster,
  playerBody,
  firefliesUD,
  firefliesLR,
  keysPressed,
  secondCamera,
  orbitControls,
  overlayMaterial,
  doorLightMaterial,
  pointsInteraction,
  firefliesMaterialUD,
  firefliesMaterialLR,
  makeBoxCollider,
  makeMeshCollider,
  removeBoxCollider,
};
